import React, { useState, useEffect } from "react";
import { Button, Input, FormText, Form, Row } from "reactstrap";
import toastr from "toastr";

import "../Styles/Code.css";
import { get } from "../../../../helpers/Api";

const RegisterCode = (props) => {
  const [code, setCode] = useState("");

  const validateCode = () => {
    get(`api/validate/${code}`).then((response) => {
      if (response.valid) {
        props.handleChange("invitation", response.data);
        props.setStep(props.step + 1);
      } else {
        toastr.error("El código de invitación no es válido", "Error");
      }
    });
  };

  useEffect(() => {
    document.body.classList.add("auth-body-bg");
  }, []);

  return (
    <div>
      <h2 className="mt-4 text-center izeven-text">BIENVENIDO A BORDO</h2>
      <p className="font-size-24 text-center text-secondary">
        Estamos encantados de tenerte con nosotros
      </p>
      <div className="p-2 mt-4">
        <Form className="form-horizontal">
          <div className="mb-1 row">
            <Input
              className="code-input"
              placeholder="000000"
              type="text"
              id="code_number"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              name="codeNumber"
            />
          </div>
          <div className="text-center mb-5">
            <FormText className="code-text" color="muted">
              Digite su número de invitación para empezar
            </FormText>
          </div>
          <div className="text-center row">
            <Button
              block
              onClick={validateCode}
              color={code ? "primary" : "secondary"}
              className="btn-code waves-effect waves-light"
              disabled={!code}
            >
              VALIDAR
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default RegisterCode;
