import React, {useEffect, useState} from "react";
import {Card, Row, Col, Label, Input, Button, FormGroup, Form} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {withNamespaces} from "react-i18next";
import {getAllCountries, getCountryStates, getStateCities} from "../../../../utils/CountryStateCityApi";
import ISelect from "../../../../components/Select";
import {axiosApi} from "../../../../helpers/Api";
import authHeader from "../../../../helpers/Api/auth-header";
import toastr from "toastr";
import {checkAuth} from "../../../../features/userSlice";
const BillingInfo = (props) => {

    const {user} = useSelector((state) => state.user);
    const dispatch = useDispatch()

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [address, setAddress] = useState("");
    const [addressComplement, setAddressComplement] = useState("");

    const getCountries = () => {
        getAllCountries()
            .then(response => setCountries(response));
    }

    const getStates = () => {
        getCountryStates(country)
            .then(response => setStates(response));
    }

    const getCities = () => {
        getStateCities(country, state)
            .then(response => setCities(response))
    }

    const update = () => {

        const data = {
            country: country,
            state: state,
            city: city,
            address: address,
            address_complement: addressComplement
        }

        axiosApi.put('api/profile/billing_info/update',data, {headers: authHeader()})
            .then(response => {
                toastr.success('Your billing info was updated.', 'Success');
                dispatch(checkAuth());
            })
            .catch(error => {
                console.log(error);
            })

    }

    useEffect(() => {
        getCountries();
    }, []);

    useEffect(() => {
        if (country) {
            getStates();
        }
    }, [country]);

    useEffect(() => {
        if (state) {
            getCities();
        }
    }, [state]);

    useEffect(() => {
        setCountry(user.billing_info ? user.billing_info.country : '');
        setState(user.billing_info ? user.billing_info.state : '');
        setCity(user.billing_info ? user.billing_info.city : '');
        setAddress(user.billing_info ? user.billing_info.address : '');
        setAddressComplement(user.billing_info ? user.billing_info.address_complement : '');
    }, [user])

    return (
        <React.Fragment>
            <Row className="mt-4 py-3 content-center" style={{marginRight: '5px', marginLeft: '20px'}}>
                <Col md="4" className="mb-4">
                    <Row className="font-size-18 mt-2  ">
                        <Col className="mb-3 izeven-text" md={12}>
                            <i className="fas fa-money-bill-alt" style={{marginRight: '10px'}}/>
                            <span>{props.t('Billing Information')}</span>
                        </Col>
                    </Row>
                    <p style={{textAlign: 'justify'}}>
                        {props.t('Consider')}
                    </p>
                </Col>
                <Col/>
                <Col md="7">
                    <Form>
                        <FormGroup>
                            <Label>{props.t('Country')}</Label>
                            <ISelect value={country} onChange={(e) => setCountry(e.value)} options={countries} placeholder="Select your Country..." />
                        </FormGroup>
                        <FormGroup>
                            <Label>{props.t('State')}</Label>
                            <ISelect value={state} onChange={(e) => setState(e.value)} options={states} placeholder={ country ? 'Select your State' : 'First select your Country' } disabled={!country} />
                        </FormGroup>
                        <FormGroup>
                            <Label>{props.t('City')}</Label>
                            <ISelect value={city} onChange={(e) => setCity(e.value)} options={cities} placeholder={ country ? 'Select your City' : 'First select your State' } disabled={!state} />
                        </FormGroup>
                        <FormGroup>
                            <Label>{props.t('Address 1')}</Label>
                            <Input id="address" value={address} onChange={(e) => setAddress(e.target.value)} type="text"/>
                        </FormGroup>
                        <FormGroup>
                            <Label>{props.t('Address op')}</Label>
                            <Input id="address-complement" value={addressComplement} onChange={(e) => setAddressComplement(e.target.value)} type="text"/>
                        </FormGroup>
                        <Button onClick={update} color="primary"
                                className="w-md waves-effect waves-light"
                        >{props.t('Update')}</Button>
                    </Form>
                </Col>

            </Row>

        </React.Fragment>
    );
}

export default withNamespaces() (BillingInfo);
