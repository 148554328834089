import React, {Component} from "react";

import {Button} from "reactstrap";
import {Link} from "react-router-dom";

import LanguageDropdown from "../../CommonForBoth/TopbarDropdown/LanguageDropdown";
import ProfileMenu from "../../CommonForBoth/TopbarDropdown/ProfileMenu";

//Import i18n
import {withNamespaces} from "react-i18next";

//Import logo Images
import logosmdark from "../../../assets/images/logo-sm-dark.png";
import logodark from "../../../assets/images/logo-dark.png";
import logosmlight from "../../../assets/images/logo-sm-light.png";
import logolight from "../../../assets/images/logo-light.png";
import AppDropDown from "./Components/AppDropDown";

const Header = (props) => {

    return (
        <>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box">
                            <Link to="#" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logosmdark} alt="" height="100%"/>
                                    </span>
                                <span className="logo-lg">
                                        <img src={logodark} alt="" height="60"/>
                                    </span>
                            </Link>

                            <Link to="#" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logosmlight} alt="" height="22"/>
                                    </span>
                                <span className="logo-lg">
                                        <img src={logolight} alt="" height="60"/>
                                    </span>
                            </Link>
                        </div>
                        <Button size="sm" color="none" type="button" onClick={props.toggleMenuCallback}
                                className="px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn">
                            <i className="ri-menu-2-line align-middle"/>
                        </Button>
                    </div>

                    <div className="d-flex">
                        <div className="dropdown d-inline-block d-lg-2 md-2 xs-2 ms-2 ">
                            <LanguageDropdown/>
                        </div>
                        <div className="dropdown d-inline-block d-lg-2 md-2 xs-2 ms-2 ">
                            <AppDropDown/>
                        </div>
                        {/*<div className="dropdown d-inline-block d-lg-2 md-2 xs-2 ms-2 ">*/}
                        {/*    <NotificationDropdown/>*/}
                        {/*</div>*/}
                        <div className="dropdown d-inline-block d-lg-2 md-2 xs-2 ms-2 ">
                            <ProfileMenu/>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default withNamespaces()(Header);
