import React, {useEffect, useState} from "react";
import icon from '../../../../assets/images/Crypto/tether.svg';
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {axiosApi} from "../../../../helpers/Api";
import authHeader from "../../../../helpers/Api/auth-header";
import {useSelector} from "react-redux";
import toastr from 'toastr'
import {withNamespaces} from "react-i18next";
import Swal from "sweetalert2";

const Tether = (props) => {

    const {user} = useSelector((state) => state.user);

    const [address, setAddress] = useState('');

    const update = () => {
        Swal.fire({
            title: 'Warning',
            text: 'Are you sure you want to change your wallet address?',
            icon: 'warning',
            confirmButtonColor: "#11EDFC",
            cancelButtonText: "Cancel",
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                axiosApi.post('api/crypto/tether', {address: address}, {headers: authHeader()})
                    .then(response => {
                        toastr.success('Your Tether wallet was updated.', 'Success');
                    })
                    .catch(error => {
                        toastr.error('Something went wrong try again later.', 'Server Error');
                    })
            }
        })
    }

    useEffect(() => {
        if (user.tether_wallet) {
            setAddress(user.tether_wallet.address);
        }
    }, [user])

    return (
        <>
            <Row className="mt-4 py-3 content-center mx-4 ">
                <Col md="4" className="mb-4">
                    <Row className="font-size-18 mt-2">
                        <Col className="mb-3 izeven-text" md={12}>
                            <img style={{marginRight: '10px'}} width="30px" src={icon}/>
                            <span>{props.t('Tether Wallet')}</span>
                        </Col>
                    </Row>
                    <p style={{textAlign: 'justify'}}>
                        {props.t('The USDT')}
                    </p>
                    <p style={{textAlign: 'justify'}}>
                        <span className="text-danger">{props.t('IMPORTANT')}</span>, {props.t('Address USDT')} (TRC20).
                    </p>
                </Col>
                <Col/>
                <Col md="7">
                    <Form>
                        <FormGroup>
                            <Label>{props.t('Network')}</Label>
                            <h5>TRON (TRC20)</h5>
                        </FormGroup>

                        <FormGroup>
                            <Label>{props.t('Wallet Address')} USDT</Label>
                            <Input value={address} onChange={(e) => setAddress(e.target.value)} id="tether-wallet"
                                   type="text"/>
                        </FormGroup>

                        <FormGroup>
                            <Button onClick={update} color="primary"
                                    className="w-md waves-effect waves-light"
                            >{props.t('Update')}</Button>
                        </FormGroup>
                    </Form>
                </Col>

            </Row>
        </>
    )
}

export default withNamespaces()(Tether);
