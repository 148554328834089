import axios from "axios";

const TOKEN = process.env.REACT_APP_COUNTRYSTATECITY_KEY;
const api_url = 'https://api.countrystatecity.in/v1'

const csc = axios.create({
    baseURL: api_url,
    headers: {
        'X-CSCAPI-KEY': TOKEN
    }
})

const rsc = axios.create({
   baseURL: 'https://restcountries.com/v3.1/'
});

export const countriesPhoneCodes = async () => {
    return await rsc.get('all?fields=name,idd,flag')
        .then(response => {
            return response.data.map((country) => {
                let code = country.idd.root + ( country.name.common === 'United States' ? '' : country.idd.suffixes[0]);
                return {label: country.flag + ' ' + country.name.common + `   (${code})`, value: code}
            });
        })
        .catch(error => error);
}


export const getAllCountries = async () => {
    return await csc.get('/countries')
        .then(response => {
            return response.data.map((country) => ({ label: country.name, value: country.iso2 }))
        })
        .catch(error => error);
}

export const getCountryStates = async (ciso) => {
    return await csc.get(`/countries/${ciso}/states`)
        .then(response => {
            return response.data.map((state) => ({ label: state.name, value: state.iso2  }))
        })
}

export const getStateCities = async (ciso, siso) => {
    return await csc.get(`/countries/${ciso}/states/${siso}/cities`)
        .then(response => {
            return response.data.map((city) => ({ label: city.name, value: city.name }))
        })
}
