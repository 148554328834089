import React, { Component, useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Transactions from "./Components/Transactions";
import WalletComp from "./Components/Wallet";
import { get } from "../../helpers/Api";
import authHeader from "../../helpers/Api/auth-header";
import { withNamespaces } from "react-i18next";
const Wallet = (props) => {
  const [wallet, setWallet] = useState({});
  const [incomes, setIncomes] = useState({});

  const getWallet = () => {
    get("api/wallet", { headers: authHeader() }).then((response) => {
      const { wallet } = response;
      const {
        wallet: walletData,
        izevenIncomes,
        terraIncomes,
        iupIncomes,
      } = wallet;

      setIncomes({
        izeven: izevenIncomes,
        realstate: terraIncomes,
        iup: iupIncomes,
      });

      walletData.amount_available = parseInt(walletData.amount_available);
      setWallet(walletData);
    });
  };

  let breadcrumbItems = [
    { title: "iZeven", link: "/" },
    { title: props.t("Wallet"), link: "#" },
  ];

  useEffect(() => {
    getWallet();
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Wallet")}
            breadcrumbItems={breadcrumbItems}
          />
          <Row className="justify-content-center">
            <Col md={5}>
              <WalletComp
                getWallet={getWallet}
                wallet={wallet}
                incomes={incomes}
              />
            </Col>
          </Row>
        </Container>

        <Container fluid>
          <h5 className="mt-5 mb-4">{props.t("TRANSACTIONS")}</h5>
          <Transactions transactions={wallet.transactions} />
        </Container>
      </div>
    </>
  );
};

export default withNamespaces()(Wallet);
