const html = `
    <p><strong>CONTRATO IPA – IZEVEN LLC</strong></p>
<p><strong>TÉRMINOS Y CONDICIONES</strong></p>
<p><strong>INTRODUCCIÓN</strong></p>
<p>IZEVEN LLC (La Compañía), le da la bienvenida a su sitio web oficial a través del cual se ofrecen y proporcionan bienes, productos y servicios sujetos a los avisos y a los términos y condiciones mediante los cuales se regulará la relación contractual con los Independent Promoter Agent (en lo sucesivo &quot;IPA&quot;).</p>
<p><strong>OBJETO</strong></p>
<p>El IPA tiene por objeto la distribución no exclusiva de los productos y servicios que comercializa La Compañía, a través de un sistema de venta directa y mercadeo en red, E-Commerce, Dropshipping, Network Marketing, Marketing Relacional o Marketing Multinivel en las diferentes plataformas a las que tienen acceso, entre otras a Izeven Master, Izeven Real Estate, Izeven Agronomy, Izeven Profit, Avanx y las demás que desarrolle La Compañía y ponga a disposición de los IPAs.</p>
<ul>
<p><strong>PRINCIPIOS RECTORES</strong></p>
</ul>
<p>La Compañía, ha determinado basar sus relaciones contractuales bajo unos principios que ha catalogado como “PRINCIPIOS RECTORES” en atención a los preceptos legales, con el fin de dar cumplimiento a los mismos y obrar con total transparencia, los cual son:</p>
<ol>
<li>ACCESO A LA INFORMACIÓN: IZEVEN, utiliza una página web la cual está disponible las 24 horas del día para que puedan consultar todo lo relacionados con los productos y/o servicios ofrecidos encontrando información objetiva, veraz y con transparencia.</li>
<li>AUTONOMÍA DE LA VOLUNTAD: Quien celebren cualquier tipo de relación contractual con La Compañía, tiene la posibilidad de obrar con total libertad, respetando los lineamentos que se determinaron para que exista un ambiente de total armonía.</li>
<li>BUENA FE: la Compañía actúa en todo momento de forma honesta y correcta.</li>
<li>CAPACIDAD: Se considera que la persona que celebre un contrato con La Compañía es legalmente capaz y, por ende, cumple con los requisitos para poder obligarse.</li>
<li>RESPETO: En IZEVEN LLC, mantenemos un trato basado en el respeto e igualdad, por tanto, se recomienda a los IPAs que la relación entre ellos y con los Usuario o Clientes se ejecuten en todo momento bajo este principio rector.</li>
<li>ÉTICA: Para La Compañía, es muy importante el compromiso, la lealtad, la calidad y la confianza, siendo este uno de los principios más importantes con el objeto de que exista una buena relación entre los IPAs y con los Usuarios o Clientes.</li>
<li>TOLERANCIA: Dados los grandes cambios que vienen presentando en el mundo y a la velocidad que se están dando, conlleva a que no todas las personas se adapten al mismo ritmo, siendo así que se debe procurar brindar un trato tolerante entre lo IPAs y con los Usuarios o Clientes.</li>
<li>LEGALIDAD: IZEVEN LLC, es una compañía que se encuentra legalmente constituida y que cumple con todos los requisitos para su operabilidad.</li>
<li>PROTECCIÓN DE DATOS PERSONALES: La Compañía, garantiza que se protegerá la información suministrada conforme a su política de privacidad que puede ser consultada en <a href="http://www.izeven.com/">www.izeven.com</a></li>
</ol>
<p>Bajo estos principios rectores y la normatividad aplicable de acuerdo con cada país, se regirán las relaciones entre IZEVEN, IPAs y los clientes.</p>
<ol>
<li>AGENTE PROMOTOR INDEPENDIENTE (IPA, POR SUS SIGLAS EN INGLÉS)</li>
</ol>
<p>El IPA, es una persona natural o jurídica que se <strong>AUSPICIA</strong> y se autoriza para promocionar, comercializar y vender productos y servicios de IZEVEN por intermedio de sus Plataformas, siendo necesario que adquieran cualquiera de las membresías ofrecidas por la compañía para acceder a todas las plataformas.</p>
<p>El Agente Promotor Independiente (IPA), es el primero de la línea ascendente de cualquier usuario en el árbol binario (Un árbol binario es una estructura de dato no lineal en la que cada nodo o punto de intersección, conexión o unión puede apuntar a uno o máximo dos nodos, por ello el nombre &quot;binario&quot;), y árbol de suscripción, además de auspiciar a otros nuevos IPA, nuevos Clientes y Clientes Preferentes, obtener membresías y adquirir rangos, los cuales son:</p>
<table>
<thead>
<tr>
<th><strong>RANGOS</strong></th>
</tr>
</thead>
<tbody>
<tr>
<td>Star</td>
</tr>
<tr>
<td>Cuarzo</td>
</tr>
<tr>
<td>Perla</td>
</tr>
<tr>
<td>Zafiro</td>
</tr>
<tr>
<td>Ruby</td>
</tr>
<tr>
<td>Esmeralda</td>
</tr>
<tr>
<td>Diamante</td>
</tr>
<tr>
<td>Doble diamante</td>
</tr>
<tr>
<td>Triple diamante</td>
</tr>
<tr>
<td>Corona</td>
</tr>
<tr>
<td>Embajador corona</td>
</tr>
<tr>
<td>Doble embajador corona</td>
</tr>
</tbody>
</table>
<p>Las condiciones para alcanzar cualquiera de los rangos se encontrará en el Anexo 1 que estará adjunto al presente documento</p>
<p>Los IPA al obtener el Volumen de Calificación (<em>Qualification Volume</em>) requerido de acuerdo con los paquetes de servicio iZeven, recibirá una bonificación y compensación por las ventas realizadas y por el apoyo que brinda a los IPA que recluta. Dicho reconocimiento se realizará de acuerdo con las condiciones establecidas que se determinarán más adelante y que se detallarán en el Anexo 1.</p>
<p>Existe la posibilidad de reclutar o vincular a otros IPA, aclarando que, no se paga una bonificación por la mera contratación de nuevos IPA, sino por el valor de las ventas, mediante el sistema binario.</p>
<ol>
<li>REQUISITOS PARA SER IPA</li>
</ol>
<p>Los documentos que se necesitan para ser IPA, son: Copia de la cédula de ciudadanía o ID de su país por ambas caras a color o Pasaporte vigente; un recibo de servicio público, un documento gubernamental o un documento bancario donde se incluya la dirección del domicilio del titular para demostrar y validar la residencia con fecha de expedición no superior a 30 días calendario; código Paypal; código de billetera digital o cuenta bancaria y aportar diligenciado el KYC. Esto en cualquier país del mundo.</p>
<p>Es importante aclarar que el IPA, debe ser mayor de edad y legalmente capaz para la aceptación de estos términos y condiciones, por tanto, se le invita a dar lectura plena de este documento y en caso de no estar de acuerdo con alguna parte con lo en ellos establecido no podrá acceder al Sitio y le invitamos a ponerse en contacto con el personal de La Compañía para aclarar sus inquietudes.</p>
<p>En este mismo orden, el IPA pagará una cuota anual por valor de Treinta y Cinco dólares americanos ($35 USD) para poder tener acceso a las herramientas digitales y de igual forma acceder y manejar su Oficina de Negocios. Si no se efectúa el pago, el sistema registrará al usuario como inactivo y se activará al momento de pagar nuevamente.</p>
<p>El pago mencionado para ser IPA, es totalmente independiente al pago mensual de Cincuenta dólares americanos ($50 USD) por la suscripción adquirida.</p>
<ol>
<li>USO DE NUESTRO SITIO WEB</li>
</ol>
<p>Todo IPA, al utilizar este sitio web, acepta el procesamiento de la información y los detalles y declara que toda la información proporcionada es veraz y corresponde a la realidad. Una vez sujeto a los términos y condiciones y al convertirse en Miembro de IZEVEN, le da derecho, según las presentes condiciones a desarrollar actividades en páginas del ecosistema como, Master, Real Estate, Agronomy, Profit App, entre otras.</p>
<p>El IPA será el único responsable de proteger la seguridad y confidencialidad de su contraseña y la identificación que se le asignó. Si el IPA autoriza el uso de su contraseña e identificación por parte de otra persona para el acceso y utilización de este sitio, será el único responsable y asumirá los riesgos de todas las comunicaciones, transmisiones y todas las obligaciones (incluidas, entre otras, las financieras) contraídas a través de dicho acceso o uso.</p>
<p>Dado el caso que, el IPA, no haya autorizado el acceso y uso del Sito, deberá notificar inmediatamente a la Compañía de cualquier uso indebido, violación o amenaza de violación de la seguridad de este Sitio.</p>
<p>IZEVEN siempre cumplirá con la ley vigente para este tipo de contrato comercial, y les recuerda a los usuarios hacer lo mismo, al igual que, dar aplicación a los principios rectores establecidos. Los IPAs y sus clientes son responsables de cumplir con todas las leyes y normativas de sus propios países. Consulte nuestra política de privacidad para obtener más información.</p>
<ol>
<li>POLÍTICA DE DATOS E INFORMACIÓN</li>
</ol>
<p>IZEVEN, en aplicación al principio de seguridad en el tratamiento de datos personales, proporcionará las medidas administrativas y tecnológicas que sean necesarias para proveer seguridad a los registros, evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.</p>
<p>Para todo IPA, cuando utiliza este sitio web y realiza pedidos a través de él, acepta proporcionarnos su dirección de correo electrónico, dirección postal y/u otros datos de contacto de manera veraz y exacta. También autoriza que podamos utilizar esta información para comunicarnos con usted en el contexto de su pedido si es necesario.</p>
<p>Respetamos su derecho a la privacidad. Para ver cómo recopilamos y usamos su información personal, consulte nuestra Política de privacidad.</p>
<ol>
<li>COMPENSACIÓN</li>
</ol>
<p>IZEVEN, maneja para todos sus IPA retribución y compensación por el sistema de Tokens virtuales (Un token es “una unidad de valor que una organización crea para gobernar su modelo de negocio y dar más poder a sus usuarios para interactuar con sus productos, al tiempo que facilita la distribución y reparto de beneficios entre todos sus miembros”) ya que el IPA recibirá una bonificación por su actividad de marketing, para lo cual debe registrarse y pagar lo correspondiente a su membresía, valores que aparecen en <www.izeven.com></p>
<p>Los tokens se cargan a la cuenta de los miembros de IZEVEN, por cada uno de sus trabajos en los diferentes frentes del ecosistema de la Compañía, además, de forma promocional, (como un servicio a los cursos educativos proporcionados por IZEVEN), por los logros y bonos, los cuales se convertirán en dólares americanos, que suman y se liquidarán dentro de los 5 primeros días de cada mes, enviando dicha sumatoria a sus respectivas wallet.</p>
<p>Los IPA cuentan con una oficina administrativa en línea para sus actividades; dicho back office le proporcionará una descripción general actualizada y completa de sus ventas, bonificaciones y su crecimiento en cifras de clientes y líneas descendentes el cual puede ver en tiempo real en <a href="http://www.izeven.com/">www.izeven.com</a></p>
<ol>
<li>OBLIGACIONES ESPECIALES DE LOS IPA COMO PROMOTORES INDEPENDIENTES Y SU SITUACIÓN JURÍDICA.</li>
<li>En esta relación es netamente comercial, los IPA actúan como promotores independientes y autónomos, obrando con total independencia de la Compañía, por tanto, no existe ni se genera ningún tipo de vínculo laboral entre el IPA e IZEVEN LLC. En consecuencia, no hay ningún tipo de exigencia por parte de la Compañía en cuanto a cumplimiento de horarios, ingresos, ventas, entregas mínimas al no existir subordinación.</li>
<li>Los IPAs no están sujetos a ninguna instrucción de la Compañía, con la excepción de sus obligaciones contractuales y asumen todos los riesgos empresariales de su actividad comercial, incluida la obligación de pagar todos sus costos comerciales y los salarios de sus empleados, si los tuviere.</li>
<li>Los IPAs establecerán y operarán sus negocios de acuerdo con principios comerciales sólidos, incluida la operación de sus propias oficinas u otros lugares de trabajo apropiados para la realización de negocios.</li>
<li>Como agentes promotores independientes, son personalmente responsables del cumplimiento de las disposiciones legales pertinentes, incluidos los requisitos fiscales y de seguridad social de cada país donde opere.</li>
<li>El IPA acuerda que la propiedad sobre el curso/paquete educativo comprado, junto con todos los accesorios y/o productos complementarios que se le suministren, se transfiere a él tras la activación de los paquetes educativos o servicios disponibles para su uso en su espacio de back-office personal proporcionado.</li>
<li>Los IPA están obligados a proteger sus contraseñas personales y nombres de usuario de terceros. La Compañía no asume ninguna responsabilidad por las acciones realizadas por una persona que no sea el IPA utilizando su nombre de usuario y contraseña.</li>
<li>Los agentes promotores independientes, no están autorizados a responder consultas de prensa sobre la Compañía, el sistema IZEVEN, sus servicios, su ecosistema, su plan de marketing o cualquier otro asunto relacionado con la Compañía, su actividad y otros servicios. El IPA está obligado a remitir inmediatamente todas las consultas de prensa a la Compañía.</li>
<li>El IPA, respetará todos los derechos de propiedad intelectual y de imagen que pertenecen a la Compañía, so pena que IZEVEN inicie las acciones legales correspondientes por los daños y/o perjuicios que le sean generados.</li>
<li>De producirse error en los pagos realizados por La Compañía, el IPA que reciba el dinero y que no correspondan al pago de sus actividades deberá reintegrar de manera inmediata o al momento de que IZEVEN lo requiera los valores que le hayan sido depositados.</li>
<li>El agente promotor, deberá suministrar toda la información requerida veraz, en caso de realizarse algún cambio en las direcciones electrónicas o físicas o del número de teléfono que se requieran para efectos de notificaciones o destino de pedidos deberá informar de manera inmediata a La Compañía el cambio.</li>
<li>El IPA obrará de manera honesta y sincera para con sus clientes, es decir, no hará publicidad engañosa.</li>
<li>El agente promotor, suministrará la información de los bienes, productos y servicios de manera clara y precisa, no inducirá a los clientes con promesa dudosas y/o engañosas ni con la promesa de beneficios especiales cuando estos estén vinculados a resultados futuros inciertos.</li>
<li>Los daños y/o perjuicios que puedan suscitar de la relación entre el IPA y sus clientes, serán asumidos directamente por el agente y asumirá bajo su exclusiva responsabilidad los gastos que de ello se deriven.</li>
<li>POLÍTICA ANTI-SPAM</li>
</ol>
<p>El abuso y el uso indebido del correo electrónico por parte de un IPA puede ser demandable por los perjudicados. La Compañía no es permisiva con el abuso del SPAM. Definición de UCE (correo electrónico comercial no solicitado o SPAM) e IZEVEN se acoge a los direccionamientos de la POLÍTICA ANTI-SPAM (Ley CAN-SPAM)</p>
<ol>
<li>COMPENSACIÓN, CONDICIONES DE PAGO, MÉTODOS DE PAGO DE BONIFICACIONES</li>
</ol>
<p>La compensación por su actividad se hace semanal o mensual dependiendo del tipo de bono, o comisión generada. La especificación la encontrará en el anexo 1.</p>
<p>Las bonificaciones y cargos por la prestación de servicios de IPA solo se pueden pagar a cuentas mantenidas a su nombre o al de una sociedad o entidad legal en relación comercial con IZEVEN, a menos que se acuerde expresamente lo contrario.</p>
<p>Si un IPA pierde su estado de IPA activo, perderá todos los reclamos de bonificaciones desde el momento en que se pierda dicho estado. Por supuesto, es posible que el IPA recupere la calificación respectiva en el futuro nuevamente, pero sin reactivación de reclamos de bonificación durante el periodo de inactividad, excepto las adquiridas con anterioridad.</p>
<p>La Compañía debe ser notificada por escrito dentro de los cinco (5) días hábiles (a partir de la fecha del pago) de cualquier bonificación u otros pagos incorrectos. Pasado este tiempo, las bonificaciones u otros pagos se considerarán aceptados y aprobados por el IPA.</p>
<p>12. LÍMITE DE RESPONSABILIDAD DE LA COMPAÑÍA</p>
<p>Se acuerda que la Compañía queda eximida de cualquier responsabilidad por daños de cualquier tipo causados por la pérdida de datos en los servidores informáticos, a menos que tales pérdidas sean causadas por negligencia grave o acciones intencionales de la Compañía, sus empleados o agentes. La Compañía trata cualquier contenido almacenado en los servidores por IPA como contenido de terceros de acuerdo con toda la legislación de protección de datos relevante y aplicable. La Compañía no es responsable de ningún dato o información incorrecta enviada por el IPA en la solicitud. La Compañía no es responsable si los IPA han proporcionado nombres de usuario incorrectos.</p>
<p>13. CAMBIOS A LOS TÉRMINOS Y CONDICIONES</p>
<p>Es posible que, IZEVEN LLC, se realicen cambios a los términos y condiciones que rigen el uso de nuestro sitio. Podemos cambiar, mover, agregar, corregir o eliminar partes del mismo. Cada vez que desee utilizar nuestro sitio, consulte los Términos y Condiciones para asegurarse que los comprende y se acoge según las actualizaciones realizadas.</p>
<p>La Compañía, se reserva el derecho de cambiar los términos y condiciones de este sitio en cualquier momento, sin embargo, cuando esto suceda informará mediante los diversos canales de comunicación que se utilizan.</p>
<p>14. PROHIBICIÓN DE CESIÓN DEL CONTRATO</p>
<p>Por ser un contrato <em>Intuitu Personae,</em> el IPA tiene la prohibición de ceder, en todo o en parte su posición contractual, bajo pena de nulidad.</p>
`;

export default html;
