import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./Styles/index.css";
import Pay from "./Components/Pay";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { axiosApi, get } from "../../helpers/Api";
import Swal from "sweetalert2";
import authHeader from "../../helpers/Api/auth-header";
import toastr from "toastr";

const Renewal = (props) => {
  const history = useHistory();

  const { user } = useSelector((state) => state.user);
  const [wallet, setWallet] = useState(null);

  const [pay, setPay] = useState(false);
  const toggle = () => setPay(!pay);

  let breadcrumbItems = [
    { title: "iZeven", link: "/" },
    { title: props.t("Renewal"), link: "#" },
  ];

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const getWallet = () => {
    get("api/wallet", { headers: authHeader() }).then((response) => {
      setWallet(response.wallet.wallet);
    });
  };

  useEffect(() => {
    getWallet();
  }, []);

  const payWithWallet = () => {
    Swal.fire({
      title: "Warning",
      text: `Are you sure you want to pay with your wallet balance? `,
      icon: "warning",
      confirmButtonColor: "#11EDFC",
      cancelButtonText: "Cancel",
      showCancelButton: true,
    }).then((result) => {
      if (wallet.amount_available < 50) {
        toastr.error(
          "You dont have enough money in your wallet.",
          "Server Error"
        );
      } else if (result.isConfirmed) {
        axiosApi
          .post(
            "api/payments/renewal",
            { wallet: true },
            { headers: authHeader() }
          )
          .then((response) => {
            toastr.success("Your renewal was payed.", "Success");
            history.push("/dashboard");
          })
          .catch((error) => {
            toastr.error(
              "Something went wrong try again later.",
              "Server Error"
            );
          });
      }
    });
  };

  return (
    <>
      <div className="page-content page-content-ipa">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Renewal")}
            breadcrumbItems={breadcrumbItems}
          />

          <Container style={{ marginTop: "100px" }}>
            <Row className="text-center align-items-center">
              <Col>
                <h1 className="text-izeven mb-4">
                  {props.t("It is time to renew your subscription")} &#x1F609;
                </h1>
                <h3 className="text-white fw-light mb-4">
                  {props.t("Keep en")}
                </h3>
                <Button
                  onClick={toggle}
                  className="mt-3 btn-buy btn-rounded"
                  size="lg"
                  color="primary"
                >
                  <i className="fas fa-credit-card" /> {props.t("Renew Now")}
                </Button>
                <br />
                <Button
                  onClick={payWithWallet}
                  className="mt-3 btn-buy btn-rounded"
                  size="lg"
                  color="primary"
                >
                  <i className="fas fa-credit-card" />{" "}
                  {props.t("Renew With Wallet")}
                </Button>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <Pay isOpen={pay} toggle={toggle} />
    </>
  );
};

export default withNamespaces()(Renewal);
