import React, { useState } from "react";
import { Card, Row, Col } from "reactstrap";
import izevenPay from "../../../assets/images/brands/izevenpay50px.png";
import { withNamespaces } from "react-i18next";
import "../../../assets/css/hr.css";
import "../../../assets/css/wallet.css";
import Withdrawal from "./Withdrawal";

const WalletComp = ({ wallet, getWallet, incomes, t }) => {
  const [withdrawal, setWithdrawal] = useState(false);
  const toggle = () => setWithdrawal(!withdrawal);

  return (
    <>
      <Card className="wallet">
        <Row className="mx-4 mt-4">
          <Col md={6} className="text-white font-size-18">
            Disponible
          </Col>
          <Col md={6} className="text-white font-size-22 text-end">
            <img src={izevenPay} />
          </Col>
        </Row>

        <strong
          className="text-white mb-2 mx-4 px-2"
          style={{ fontSize: "35px" }}
        >
          $ {wallet.amount_available} <small>USD</small>{" "}
        </strong>

        <hr className="hr-wallet mx-3" />

        <Row className="text-white mb-3 mt-3">
          <Col onClick={toggle} className="text-center">
            <i className="fas fa-arrow-down fa-3x mb-3" />
            <p>{t("Withdrawal")}</p>
          </Col>
          <Col className="text-center">
            <i className="fa fa-wallet fa-3x mb-3" />
            <p>
              {t("ID Wallet")}: {wallet.code}
            </p>
          </Col>
        </Row>
      </Card>
      <Row md={12} className="text-white font-size-18">
        <Col md={6} xs={12}>
          <div className="mini-wallet izeven">
            <h5>
              Izeven
              {/* <small>Ingresos</small> */}
            </h5>
            <hr />
            <h3>${incomes.izeven}</h3>
          </div>
        </Col>
        <Col md={6} xs={12}>
          <div className="mini-wallet realstate">
            <h5>
              Izeven <span className="spacing">REAL ESTATE</span>
              {/* <small>Ingresos</small> */}
            </h5>
            <hr />
            <h3>${incomes.realstate}</h3>
          </div>
        </Col>
        <Col md={6} xs={12}>
          <div className="mini-wallet agronomy">
            <h5>
              Izeven <span className="spacing">AGRONOMY</span>
              {/* <small>Ingresos</small> */}
            </h5>
            <hr />
            <h3>$0</h3>
          </div>
        </Col>
        <Col md={6} xs={12}>
          <div className="mini-wallet iup">
            <h5>
              Izeven <span className="spacing">PROFIT</span>
              {/* <small>Ingresos</small> */}
            </h5>
            <hr />
            <h3>${incomes.iup}</h3>
          </div>
        </Col>
      </Row>
      <Withdrawal
        wallet={wallet}
        getWallet={getWallet}
        toggle={toggle}
        isOpen={withdrawal}
      />
    </>
  );
};

export default withNamespaces()(WalletComp);
