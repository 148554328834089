import React from "react";
import {Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SideLink from "./Components/SideLink";
import Upgrade from "./Components/Upgrade/Upgrade";
import {withNamespaces} from "react-i18next";
import PasswordChange from "./Components/PasswordChange/PasswordChange";

const Settings = (props) => {

    let breadcrumbItems = [
        {title: "iZeven", link: "/"},
        {title: props.t('Settings'), link: "#"},
    ]

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Settings" breadcrumbItems={breadcrumbItems}/>
                </Container>
                <Upgrade />
                <SideLink />
                <PasswordChange/>
            </div>
        </>
    )
}

export default withNamespaces()(Settings);
