import React, {useEffect} from 'react';
import { useDispatch } from 'react-redux';
import {logout} from "../../features/userSlice";
import {withRouter} from "react-router-dom";

const Logout = (props) => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logout())
            .then(() => props.history.push('/login'));
    })

    return (
        <>
            <h1>&nbsp;</h1>
        </>
    )
}

export default withRouter(Logout);

