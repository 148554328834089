import React, {useState, useEffect} from "react";
import {Input, Button, Label, Form, FormGroup} from "reactstrap";
import toastr from 'toastr'

import '../Styles/Register.css';
import ISelect from "../../../../components/Select";

const PersonalInfo = (props) => {

    const identificationTypes = [
        { label: 'Cedula', value: 'C' },
        { label: 'Passport', value: 'P' }
    ]

    const [name, setName] = useState("");
    const [idType, setIdType] = useState("");
    const [idNumber, setIdNumber] = useState("");
    const [birthday, setBirthday] = useState("");

    const validate = () => {
        return name && idType && idNumber && birthday;
    }

    const next = () => {
        if (validate()) {
            props.setStep(props.step + 1)
        } else {
            toastr.error('There are no fields to be entered', 'Error');
        }
    }

    useEffect(() => {
        document.body.classList.add("auth-body-bg");
    }, []);

    const handleChange = (e, setState, id = null) => {
        setState(e.target ? e.target.value : e.value);
        props.handleChange(id ? id : e.target.id, e.target ? e.target.value : e.value);
    }

    return (
        <div>
            <h4 className="mt-2 text-center izeven-text">PERSONAL INFO</h4>
            <p className="text-center text-secondary">Let us get to know you...</p>

            <div className="mt-4 row">
                <Form className="mb-4">
                    <FormGroup>
                        <Label>Full Name</Label>
                        <Input type="text" value={name} onChange={(e) => handleChange(e, setName)} name="name"
                               id="name"/>
                    </FormGroup>

                    <FormGroup>
                        <Label>Identification Type</Label>
                        <ISelect value={idType} onChange={(e) => handleChange(e, setIdType, 'identification_type')} options={identificationTypes} placeholder="Select your identification type..." />
                    </FormGroup>

                    <FormGroup>
                        <Label>Identification Number</Label>
                        <Input className="mb-2" type="number" value={idNumber}
                               onChange={(e) => handleChange(e, setIdNumber)} name="identification_number" id="identification_number"/>
                    </FormGroup>

                    <FormGroup>
                        <Label>Birthday</Label>
                        <Input className="mb-2" type="date" value={birthday}
                               onChange={(e) => handleChange(e, setBirthday)}
                               name="birthday" id="birthday"/>
                    </FormGroup>
                </Form>

                <div className="text-center">
                    <Button onClick={next} color="primary" className="w-md waves-effect waves-light" type="submit">Next</Button>
                </div>
            </div>
        </div>
    );
}

export default PersonalInfo;
