import React, { useEffect } from "react";
import { Button, Form } from "reactstrap";
const RegisterFinished = (props) => {
    useEffect(() => {
        document.body.classList.add("auth-body-bg");
    }, []);
    return (
        <div className="text-center mt-4">
            <i className="fas fa-check-circle fa-5x text-success mb-4"/>
            <h4 className="font-size-18 mt-2  text-center izeven-text">REGISTER SUCCESSFULLY</h4>
            <p className="text-center text-secondary">Welcome to Izeven, now you can enter the platform.</p>
            <div className="p-2 mt-2">
                <Form className="form-horizontal" >
                    <div className="text-center ">
                        <a className="btn btn-primary w-md waves-effect waves-light" href="/login">GO TO LOGIN</a>
                    </div>
                </Form>
            </div>

        </div>
    );
}

export default RegisterFinished;
