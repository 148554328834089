import React, {useState} from "react";
import {Button, Col, Container, Row} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import './Styles/index.css';
import Pay from "./Components/Pay";
import {withNamespaces} from "react-i18next";
const Ipa = (props) => {

    const [pay, setPay] = useState(false);
    const toggle = () => setPay(!pay);

    let breadcrumbItems = [
        {title: "iZeven", link: "/"},
        {title: "IPA", link: "#"},
    ]

    return (
        <>
            <div className="page-content page-content-ipa">
                <Container fluid>
                    <Breadcrumbs title="IPA" breadcrumbItems={breadcrumbItems}/>

                    <Container style={{ marginTop: '100px' }}>
                        <Row className="text-center align-items-center">
                            <Col>
                                <h1 className="text-izeven mb-4">{props.t('Want more')} 😄</h1>
                                <h3 className="text-white fw-light mb-4">
                                    {props.t('Become an')} <strong>IPA</strong> {props.t('IPA')}.
                                </h3>
                                <Button onClick={toggle} className="btn-buy btn-rounded" size="lg"
                                        color="primary"><i
                                    className="fas fa-credit-card"/> {props.t('Buy Now')}</Button>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>

            <Pay isOpen={pay} toggle={toggle} />
        </>
    )
}

export default withNamespaces()(Ipa);
