import React, {useEffect, useState} from "react";
import {Input, Card, Row, Col, Label, CustomInput, FormGroup, Button, Form} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {withNamespaces} from "react-i18next";
import {axiosApi} from "../../../../helpers/Api";
import authHeader from "../../../../helpers/Api/auth-header";
import toastr from "toastr";
import {checkAuth} from "../../../../features/userSlice";

const ProfileForm = (props) => {

    const dispatch = useDispatch()

    const { user } = useSelector((state) => state.user);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [idNumber, setIdNumber] = useState("");
    const [birth, setBirth] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const update = () => {
        const data = {
            name: name,
            birthday: birth,
            phone: phoneNumber
        }

        axiosApi.put('api/profile/update',data, {headers: authHeader()})
            .then(response => {
                toastr.success('Your profile info was updated.', 'Success');
                dispatch(checkAuth());
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        setName(user.name);
        setEmail(user.email);
        setIdNumber(user.complete_info ? user.complete_info.identification_number : '');
        setBirth(user.complete_info ? user.complete_info.birthday : '');
        setPhoneNumber(user.complete_info ? user.complete_info.phone : '');
    }, [user]);


    return (
        <React.Fragment>
            <Row className="mt-4 py-3 content-center" style={{marginRight: '5px', marginLeft: '20px'}}>
                <Col md="4" className="mb-4">
                    <Row className="font-size-18 mt-2  ">
                        <Col className="mb-3 izeven-text" md={12}>
                            <i className="fas fa-user" style={{marginRight: '10px'}}/>
                            <span>{props.t('User Profile')}</span>
                        </Col>
                    </Row>
                    <p style={{ textAlign: 'justify' }}>
                        {props.t('Your user')}
                    </p>
                </Col>
                <Col/>
                <Col md="7">
                    <Form>
                        <FormGroup>
                            <Label>{props.t('Name')}</Label>
                            <Input value={name} onChange={(e) => setName(e.target.value)} id="name" type="text" />
                        </FormGroup>

                        <FormGroup>
                            <Label>{props.t('Email')}</Label>
                            <Input disabled value={email} onChange={(e) => setEmail(e.target.value)} id="email" type="text" />
                        </FormGroup>

                        <FormGroup>
                            <Label>{props.t('Identification Number')}</Label>
                            <Input disabled value={idNumber} id="idNumber" type="text" />
                        </FormGroup>

                        <FormGroup>
                            <Label>{props.t('Birthdate')}</Label>
                            <Input value={birth} onChange={(e) => { setBirth(e.target.value) }} id="birth" type="date" />
                        </FormGroup>

                        <FormGroup>
                            <Label>{props.t('Phone Number')}</Label>
                            <Input value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} id="phoneNumber" type="number" />
                        </FormGroup>

                        <Button onClick={update} color="primary"
                                className="w-md waves-effect waves-light"
                        >{props.t('Update')}</Button>
                    </Form>
                </Col>

            </Row>

        </React.Fragment>
    );
}

export default withNamespaces() (ProfileForm);
