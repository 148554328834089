import React from "react";
import Select from 'react-select'

const ISelect = ({ options, placeholder, onChange, disabled = false, value = ''}) => {
    return (
        <Select
            value = {
                options.filter(option =>
                    option.value === value)
            }
            onChange={onChange}
            classNamePrefix="react-select"
            options={options}
            isLoading={!options.length}
            isDisabled={disabled}
            placeholder={placeholder}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: '#11EDFC',
                    primary75: 'rgba(254,96,0,0.75)',
                    primary50: 'rgba(254,96,0,0.5)',
                    primary25: 'rgba(254,96,0,0.25)',
                },
            })}
        />
    )
}

export default ISelect;
