const authHeader = () => {
    const accessToken = localStorage.getItem('auth_token');

    if (accessToken) {
        return { Authorization: 'Bearer ' + accessToken };
    } else {
        return {};
    }
}

export default authHeader;
