import React, { Component } from "react";
import { Container, Card, Row, Col } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import BankForm from "./Components/BankForm";
import BillingInfo from "./Components/BillingInfo";
import KYCVerification from "./Components/KYC";
import ProfileBar from "./Components/ProfileBar";
import ProfileForm from "./Components/ProfileForm";
import Tether from "./Components/Tether";
import {withNamespaces} from "react-i18next";
const Profile = (props) => {

    let breadcrumbItems = [
        { title: "iZeven", link: "/" },
        { title: props.t('Profile'), link: "#" },
    ]

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={props.t('Profile')} breadcrumbItems={breadcrumbItems} />
                    <Row>
                        <Col md={3}>
                            <ProfileBar />
                        </Col>
                        <Col md={9}>
                            <Card>
                                <ProfileForm />
                            </Card>
                            <Card>
                                <BillingInfo />
                            </Card>
                            <Card>
                                <BankForm />
                            </Card>
                            <Card>
                                <Tether />
                            </Card>
                            <Card>
                                <KYCVerification/>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default withNamespaces() (Profile);
