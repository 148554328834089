import React from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import {useSelector} from "react-redux";
import {withNamespaces} from "react-i18next";

const Rank = (props) => {

    const { user } = useSelector((state) => state.user);

    return (
        <Card>
            <CardBody>
                <Row className="text-center align-items-center">
                    <Col md={5}>
                        <img src={user.node.rank.image} width="30%"/>
                    </Col>
                    <Col>
                        <h5 className="text-secondary">{ props.t('Welcome to office') }</h5>
                        <h2 className="text-izeven">{ user.name.split(' ')[0] }</h2>
                        <h4>{ user.node.rank.name }</h4>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default withNamespaces()(Rank);
