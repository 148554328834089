import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import "../../scss/dashboar.scss";
import { axiosApi } from "../../../../helpers/Api";
import authHeader from "../../../../helpers/Api/auth-header";
import { withNamespaces } from "react-i18next";
const Metrics = (props) => {
  const [directSales, setDirectSales] = useState(0);
  const [networkBonus, setNetworkBonus] = useState(0);
  const [generationBonus, setGenerationBonus] = useState(0);
  const [additionalBonus, setAdditionalBonus] = useState(0);

  const [series, setSeries] = useState([0, 0, 0, 0]);
  const [options, setOptions] = useState({
    labels: [
      "Direct Sales",
      "Network Bonus",
      "Generation Bonus",
      "Aditional Bonus",
    ],
    plotOptions: {
      pie: {
        donut: {
          size: "65%",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    colors: ["#0054F3", "#4180f4", "#05c0cd", "#11EDFC"],
  });

  const getMetrics = async () => {
    await axiosApi
      .get("api/wallet/statistics", { headers: authHeader() })
      .then((response) => {
        let direct_sales = parseFloat(response.data.direct_sales);
        let network_bonus = parseFloat(response.data.network_bonus);
        let generation_bonus = parseFloat(response.data.generation_bonus);
        let additional_bonus = parseFloat(response.data.additional_bonus);

        let total =
          direct_sales + network_bonus + generation_bonus + additional_bonus;

        setSeries([
          Math.round((direct_sales * 100) / total),
          Math.round((network_bonus * 100) / total),
          Math.round((generation_bonus * 100) / total),
          Math.round((additional_bonus * 100) / total),
        ]);

        setDirectSales(direct_sales);
        setNetworkBonus(network_bonus);
        setGenerationBonus(generation_bonus);
        setAdditionalBonus(additional_bonus);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getMetrics();
  }, []);

  return (
    <Card>
      <CardBody>
        <h4 className="card-title mb-4">{props.t("My Metrics")}</h4>

        <div id="donut-chart" className="apex-charts">
          <ReactApexChart
            options={options}
            series={series}
            type="donut"
            height={293}
          />
        </div>

        <Row className="mt-4">
          <Col xs={6}>
            <div className="text-center mt-4">
              <p className="mb-2 text-truncate">
                <i
                  className="mdi mdi-circle font-size-10 me-1"
                  style={{ color: "#0054F3" }}
                />
                {props.t("Direct Sales")}
              </p>
              <h5>${directSales}</h5>
            </div>
          </Col>
          <Col xs={6}>
            <div className="text-center mt-4">
              <p className="mb-2 text-truncate">
                <i
                  className="mdi mdi-circle font-size-10 me-1"
                  style={{ color: "#4180f4" }}
                />
                {props.t("Network Bonus")}{" "}
              </p>
              <h5>${networkBonus}</h5>
            </div>
          </Col>
          <Col xs={6}>
            <div className="text-center mt-4">
              <p className="mb-2 text-truncate">
                <i
                  className="mdi mdi-circle font-size-10 me-1"
                  style={{ color: "#05c0cd" }}
                />{" "}
                {props.t("Generation Bonus")}
              </p>
              <h5>${generationBonus}</h5>
            </div>
          </Col>
          <Col xs={6}>
            <div className="text-center mt-4">
              <p className="mb-2 text-truncate">
                <i
                  className="mdi mdi-circle font-size-10 me-1"
                  style={{ color: "#11EDFC" }}
                />{" "}
                {props.t("Aditional Bonus")}
              </p>
              <h5>${additionalBonus}</h5>
            </div>
          </Col>
          <Col xs={12}>
            <div className="text-center mt-4">
              <p className="mb-2 text-truncate text-primary ">
                {props.t("BONO TOTAL GANADO")}
              </p>
              <h5>
                $
                {directSales + networkBonus + generationBonus + additionalBonus}
              </h5>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default withNamespaces()(Metrics);