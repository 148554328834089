import React, {Component, useEffect} from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import {Link, withRouter} from "react-router-dom";

//i18n
import {withNamespaces} from 'react-i18next';
import {useSelector} from "react-redux";

import '../../../assets/css/sidebar.css';

const SidebarContent = (props) => {

    const {user} = useSelector((state) => state.user);

    useEffect(() => {
        initMenu();
    }, []);

    const initMenu = () => {
        new MetisMenu("#side-menu");

        var matchingMenuItem = null;
        var ul = document.getElementById("side-menu");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    }

    const activateParentDropdown = (item) => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    return (
        <>
            <div id="sidebar-menu">

                <ul className="metismenu list-unstyled" id="side-menu">
                    <li className="menu-title">{props.t('Menu')}</li>

                    <li>
                        <Link to="/dashboard" className="waves-effect">
                            <i className="ri-dashboard-line"/>
                            <span className="ms-1">{props.t('Dashboard')}</span>
                        </Link>
                    </li>

                    <li>
                        <Link to="/profile" className=" waves-effect">
                            <i className="ri-user-2-line"/>
                            <span className="ms-1">{props.t('My Profile')}</span>
                        </Link>
                    </li>

                    <li>
                        {
                            user.subscription ?
                                <Link to="/wallet" className="">
                                    <i className="ri-wallet-line"/>
                                    <span className="ms-1">{props.t('My Wallet')}</span>
                                </Link> :
                                <a className="disabled waves-effect">
                                    <i className="ri-wallet-line"/>
                                    <span className="ms-1">{props.t('My Wallet')}</span>
                                </a>
                        }
                    </li>
                    {/*<li>*/}
                    {/*    {*/}
                    {/*        user.subscription ?*/}
                    {/*            <Link to="/" className=" waves-effect">*/}
                    {/*                <i className="ri-price-tag-3-line"/>*/}
                    {/*                <span className="ms-1">{props.t('My Bonuses')}</span>*/}
                    {/*            </Link> :*/}
                    {/*            <a className="disabled waves-effect">*/}
                    {/*                <i className="ri-price-tag-3-line"/>*/}
                    {/*                <span className="ms-1">{props.t('My Bonuses')}</span>*/}
                    {/*            </a>*/}
                    {/*    }*/}
                    {/*</li>*/}
                    <li>
                        {
                            user.subscription && !user.expired && user.ipa ?
                                <Link to="/network" className=" waves-effect">
                                    <i className="ri-line-chart-line"/>
                                    <span className="ms-1">{props.t('My Network')}</span>
                                </Link> :
                                <a className="disabled waves-effect">
                                    <i className="ri-line-chart-line"/>
                                    <span className="ms-1">{props.t('My Network')}</span>
                                </a>
                        }
                    </li>
                    <li>
                        {
                            user.subscription && !user.expired && user.ipa ?
                                <Link to="/generation" className=" waves-effect">
                                    <i className="ri-user-2-line"/>
                                    <span className="ms-1">{props.t('Generation Tree')}</span>
                                </Link> :
                                <a className="disabled waves-effect">
                                    <i className="ri-user-2-line"/>
                                    <span className="ms-1">{props.t('Generation Tree')}</span>
                                </a>
                        }
                    </li>

                </ul>
            </div>
        </>
    )
}

export default withRouter(withNamespaces()(SidebarContent));
