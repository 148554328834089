import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import Side from "./Components/Side";
import { axiosApi } from "../../../../helpers/Api";
import authHeader from "../../../../helpers/Api/auth-header";
import { withNamespaces } from "react-i18next";
import moment from "moment";
const Sides = (props) => {
  const [leftData, setLeftData] = useState([]);
  const [rightData, setRightData] = useState([]);

  const [leftPoints, setLeftPoints] = useState(0);
  const [rightPoints, setRightPoints] = useState(0);

  const [leftUsers, setLeftUsers] = useState(0);
  const [rightUsers, setRightUsers] = useState(0);

  const getUsersBySide = () => {
    axiosApi
      .get("api/stats/users", { headers: authHeader() })
      .then((response) => {
        setLeftUsers(response.data.left_users);
        setRightUsers(response.data.right_users);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSidesStats = () => {
    axiosApi
      .get("api/stats/network", { headers: authHeader() })
      .then((response) => {
        setLeftData(response.data.left);
        setRightData(response.data.right);

        setRightPoints(response.data.right_points);
        setLeftPoints(response.data.left_points);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const cicleEnd = () => {
    const today = new Date();
    const cicleEnd =
      today.getDate() < 16
        ? new Date(today.getFullYear(), today.getMonth(), 15)
        : new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return cicleEnd;
  };

  const getCicleNumber = () => {
    let flagDate = new Date("2024-08-01");
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    let cicle = 0;

    while (flagDate <= today) {
      const cicleEnd =
        flagDate.getDate() < 16
          ? new Date(flagDate.getFullYear(), flagDate.getMonth(), 15)
          : new Date(flagDate.getFullYear(), flagDate.getMonth() + 1, 0);

      if (today > cicleEnd) {
        cicle++;
      }

      flagDate = new Date(
        cicleEnd.getFullYear(),
        cicleEnd.getMonth(),
        cicleEnd.getDate() + 1
      );
    }

    return cicle;
  };

  useEffect(() => {
    getSidesStats();
    getUsersBySide();
  }, []);

  return (
    <Card>
      <CardBody>
        <h4 className="card-title mb-4">{props.t("Network Stats")}</h4>
        <Row className="align-items-center">
          <Col md={12}>
            <h4 className="text-izeven-gradient">
              VOLUMEN DEL CICLO #{getCicleNumber()}
            </h4>
            <p>
              Este ciclo finalizará el{" "}
              {moment(cicleEnd()).format("DD MMM YYYY")}, y al día siguiente
              comenzará el {getCicleNumber() + 1} ciclo.
            </p>
            {/* <h5>
              {" "}
              {"Users in side"}{" "}
              <small className="text-secondary">{"All Time"}</small>:{" "}
              {leftUsers}{" "}
            </h5> */}
            <hr />
            <Row className="text-center">
              <Col className="col-6">
                <h5 className="mb-1 text-izeven">
                  Izquierda <i className="fas text-izeven fa-arrow-left" />{" "}
                </h5>
                <h2>
                  {leftPoints.cicle} {"puntos"}
                </h2>
              </Col>
              <Col className="col-6">
                <h5 className="mb-1 text-izeven">
                  <i className="fas text-izeven fa-arrow-right" /> Derecha
                </h5>
                <h2>
                  {rightPoints.cicle} {"puntos"}
                </h2>
              </Col>
            </Row>
          </Col>
          <Col md={12}></Col>
        </Row>
        <hr />
        <Row className="align-items-center">
          <Col md={12}>
            <h4 className="text-izeven-gradient">VOLUMEN DEL MES</h4>
            <h4 className="text-izeven-gradient">
              <small>(Carrera de Liderazgo)</small>
            </h4>
            <p>
              Tu volumen mensual actual está cerca de alcanzar tu próximo rango.
              Sigue trabajando duro y aprovechando tus habilidades para lograr
              tus objetivos. ¡Estamos seguros de que lo lograrás!
            </p>
            <br />
            <Row className="text-center">
              <Col className="col-6">
                <h5 className="mb-1 text-izeven">
                  Izquierda <i className="fas text-izeven fa-arrow-left" />{" "}
                </h5>
                <h2>
                  {leftPoints.month} {"puntos"}
                </h2>
              </Col>
              <Col className="col-6">
                <h5 className="mb-1 text-izeven">
                  <i className="fas text-izeven fa-arrow-right" /> Derecha
                </h5>
                <h2>
                  {rightPoints.month} {"puntos"}
                </h2>
              </Col>
            </Row>
          </Col>
          <Col md={12}></Col>
        </Row>
        <hr />
        <Row className="align-items-center">
          <Col md={12}>
            <h4 className="text-izeven-gradient">USUARIOS EN TU RED</h4>
            <p>
              Aquí puedes ver el total de usuarios en tus dos lados, lo que te
              da una visión general de tu red. ¡No hay límites para lo que
              puedes lograr!
            </p>
            <br />
            <Row className="text-center">
              <Col className="col-6">
                <h5 className="mb-1 text-izeven">
                  Izquierda <i className="fas text-izeven fa-arrow-left" />{" "}
                </h5>
                <h2>{leftUsers} usuarios</h2>
              </Col>
              <Col className="col-6">
                <h5 className="mb-1 text-izeven">
                  <i className="fas text-izeven fa-arrow-right" /> Derecha
                </h5>
                <h2>{rightUsers} usuarios</h2>
              </Col>
            </Row>
          </Col>
          <Col md={12}></Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default withNamespaces()(Sides);
