import React, {useEffect} from "react";
import {Button, Form} from "reactstrap";
import "../Styles/Terms.css";

const Terms = (props) => {

    useEffect(() => {
        document.body.classList.add("auth-body-bg");
    }, []);

    const completeRegister = () => {
        props.submit()
    }

    const body =
        '    <p style="text-indent: 0pt;text-align: left;"><br /></p>\n' +
        '    <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">Somos una\n' +
        '        compañía en expansión, gracias a que estamos enfocados en ser de ayuda para las decisiones que tomen nuestros\n' +
        '        Usuarios y sean de gran impacto en sus vidas, convirtiéndose esto en la razón de ser para IZEVEN, donde buscamos\n' +
        '        un crecimiento para que cada vez sean más las personas favorecidas con nuestros productos y/o servicios.</p>\n' +
        '    <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">Nuestro\n' +
        '        objetivo es buscar una armonía en nuestra relación contractual dando cumplimiento a la normatividad aplicable,\n' +
        '        por tanto, hemos desarrollado la presente política de devoluciones a través de la cual pretendemos brindar una\n' +
        '        amplia posibilidad a nuestros Usuarios para que conozcan lo que IZEVEN y sus compañías derivadas (Master, Real Estate\n' +
        '        Wordl Capital, Tivigow) han traído para ustedes y de esta manera puedan decidir sobre su continuidad, o caso\n' +
        '        contrario, se puedan retirar respetando su voluntad.</p>\n' +
        '    <p style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">Con IZEVEN, lo\n' +
        '        que pretendemos es la adaptación a los cambios acelerados que está dando el mundo, especialmente en los negocios\n' +
        '        e inversiones, es por ello que con los productos y/o servicios ofrecidos intentamos encaminarles para que\n' +
        '        potencialicen y exploten sus habilidades y de esta manera logren diseñar <i>su futuro ahora</i>.</p>\n' +
        '    <p style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">IZEVEN, trabaja regido bajo una\n' +
        '        serie de principios, dentro de los cuales se resaltan:</p>\n' +
        '    <ol id="l1">\n' +
        '        <li data-list-text="a.">\n' +
        '            <p style="padding-top: 9pt;padding-left: 26pt;text-indent: -21pt;line-height: 108%;text-align: justify;">\n' +
        '                ACCESO A LA INFORMACIÓN: IZEVEN, utiliza una página web la cual está disponible las 24 horas del día\n' +
        '                para que puedan consultar todo lo relacionados con nuestros productos y/o servicios, los términos y\n' +
        '                condiciones encontrando información objetiva y veraz.</p>\n' +
        '        </li>\n' +
        '        <li data-list-text="b.">\n' +
        '            <p style="padding-left: 26pt;text-indent: -18pt;line-height: 108%;text-align: justify;">AUTONOMÍA DE LA\n' +
        '                VOLUNTAD: Nuestros Términos y Condiciones, establecen que las personas que celebren cualquier tipo de\n' +
        '                relación contractual con nosotros deben cumplir con una serie de requisitos dentro de las cuales está la\n' +
        '                capacidad, y en atención a ello, damos la opción que puedan ingresar y retirarse en el momento que\n' +
        '                deseen acogiéndose a esta política.</p>\n' +
        '        </li>\n' +
        '        <li data-list-text="c.">\n' +
        '            <p style="padding-left: 26pt;text-indent: -18pt;line-height: 107%;text-align: justify;">BUENA FE: Nuestro\n' +
        '                interés es ver el crecimiento de nuestros Usuarios, por tanto, obramos de forma honesta y correcta\n' +
        '                garantizando así nuestro compromiso con cada uno de ustedes.</p>\n' +
        '        </li>\n' +
        '        <li data-list-text="d.">\n' +
        '            <p style="padding-left: 26pt;text-indent: -18pt;line-height: 107%;text-align: justify;">CAPACIDAD: Se\n' +
        '                considera que la persona que celebre un contrato con IZEVEN, es legalmente capaz, permitiendo ello que\n' +
        '                se acoja a lo establecido en la presente política.</p>\n' +
        '        </li>\n' +
        '        <li data-list-text="e.">\n' +
        '            <p style="padding-left: 26pt;text-indent: -18pt;line-height: 107%;text-align: justify;">PACTA SUNT SERVANDA:\n' +
        '                Las obligaciones y deberes adquiridos, se convierte en Ley para las partes y son de estricto\n' +
        '                cumplimiento.</p>\n' +
        '            <p style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">En\n' +
        '                atención a nuestros principios y las distintas normatividades aplicables, IZEVEN determinó que esta\n' +
        '                política de devoluciones se haría pensando en los Usuarios, permitiendo que a través de los tiempos\n' +
        '                establecidos conozcan a fondo de nuestros productos y/o servicios y en caso de no sentirse a gusto tenga\n' +
        '                la posibilidad de retirarse en el momento que deseen.</p>\n' +
        '            <p style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">Es\n' +
        '                importante, mencionar que dentro de los primeros treinta (30) días, IZEVEN se reserva el derecho para\n' +
        '                establecer condiciones frente a la devolución de los aportes realizados por los Usuarios, de la\n' +
        '                siguiente manera:</p>\n' +
        '            <ol id="l2">\n' +
        '                <li data-list-text="1.">\n' +
        '                    <p\n' +
        '                        style="padding-top: 8pt;padding-left: 41pt;text-indent: -18pt;line-height: 107%;text-align: left;">\n' +
        '                        Si el usuario solicita el retiro dentro de los primeros cinco (5) días hábiles posteriores a su\n' +
        '                        suscripción se le hará una devolución del 100% de sus aportes.</p>\n' +
        '                </li>\n' +
        '                <li data-list-text="2.">\n' +
        '                    <p style="padding-left: 41pt;text-indent: -18pt;line-height: 107%;text-align: left;">Si el usuario\n' +
        '                        solicita el retiro entre el día seis (6) y hasta el día quince (15) posterior a su suscripción\n' +
        '                        se hará una devolución del 90% de sus aportes.</p>\n' +
        '                </li>\n' +
        '                <li data-list-text="3.">\n' +
        '                    <p style="padding-left: 41pt;text-indent: -18pt;line-height: 109%;text-align: left;">Si el usuario\n' +
        '                        solicita el retiro entre el día dieciséis (16) y hasta el día treinta (30) posterior a su\n' +
        '                        suscripción se hará una devolución del 80% de sus aportes.</p>\n' +
        '                </li>\n' +
        '            </ol>\n' +
        '        </li>\n' +
        '    </ol>\n' +
        '    <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">Es importante\n' +
        '        hacer claridad que, una vez cumplidos los términos<span class="s2">1</span> mencionados anteriormente, el\n' +
        '        Usuario que desee retirarse no tendrá derecho a ningún tipo de devolución y/o reconocimiento por parte de\n' +
        '        IZEVEN, ya que, hemos brindado un tiempo prudente para que conozcan nuestros productos y/o servicios puestos a\n' +
        '        su disposición.</p>\n' +
        '    <p style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 109%;text-align: justify;">Resaltamos que\n' +
        '        ustedes son nuestra razón de ser y esto nos conlleva a retarnos para mejorar cada día y ser los aliados\n' +
        '        perfectos para que hagan realidad su futuro.</p>\n' +
        '    <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 109%;text-align: justify;">Esta política\n' +
        '        podrá ser sometida a cambios, y dichos cambios se notificarán debidamente a través de los medios determinados\n' +
        '        por la Compañía.</p>\n' +
        '    <p style="text-indent: 0pt;text-align: left;"><br /></p>\n' +
        '    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 1pt;text-align: left;" />\n' +
        '    <p class="s3" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">1<span class="s4"> Los\n' +
        '            cinco (5) primeros días serán contados como hábiles, a partir del día seis (6) serán tomados como días\n' +
        '            calendarios.</span></p>'

    return (
        <div>
            <div className="izeven-text font-size-18 mb-4">
                <i onClick={() => props.setStep(props.step - 1)}
                   className="fas fa-arrow-left  text-center izeven-text"/> Back
            </div>
            <h4 className="font-size-18 mt-2  text-center izeven-text">TERMS & CONDITIONS</h4>
            <h5 className="text-center text-secondary">Let's be crystal clear</h5>
            <div className=" mt-4 container p-2">
                <Form className="form-horizontal">
                    <div className="terms mb-2 py-2 ">
                        <div className="block-content">
                            <p className="p3"><span
                                className="s1"><strong>IZEVEN</strong> <strong>LIMITED LIABILITY COMPANY</strong> es una compañía registrada en Estados Unidos dedicada al desarrollo de: aplicativos tecnológicos educativos presenciales y digitales, creación de sitios web, plataformas digitales para transporte en distintas modalidades, inversiones en diferentes sistemas como ( crowdfunding inmobiliario); ventas digitales al por menor por el sistema de E-comm6+erce y Drop shipping, todo esto ajustados a la normativa y leyes actuales, impulsada a través del sistema de Network marketing o mercadeo en red, IZEVEN también se encuentra registrada y con presencia corporativa en Colombia como <strong>IZEVEN COLOMBIA SAS </strong>que para efectos de éstos términos se distinguirá como el “Sitio” o solo como <strong>IZEVEN</strong>.</span>
                            </p> <p className="p4">&nbsp;</p> <p className="p5"><span className="s1"><strong>POLÍTICA DE PRIVACIDAD</strong></span>
                        </p> <p className="p3"><span className="s1">En IZEVEN<span
                            className="Apple-converted-space">&nbsp; </span>la privacidad de nuestros Clientes y Usuarios es primordial.</span>
                        </p> <p className="p3"><span className="s1">Los Usuarios y Clientes juntos se denominan los USUARIOS; para esta Política de privacidad.</span>
                        </p> <p className="p3"><span className="s1">Su acceso o uso del Sitio Web o de los Servicios significará que ha leído, comprendido y acordó estar sujeto a la Política de privacidad. Al acceder o utilizar cualquier sitio web o servicios, también declara que tiene la autoridad legal según la ley aplicable (incluida, entre otras, la edad requisito) para aceptar la Política de privacidad en su nombre y / o en cualquier otra persona que represente en relación con su uso del sitio web o los servicios. Si no está de acuerdo con la privacidad Política, no está autorizado a utilizar el sitio web o los servicios.</span>
                        </p> <p className="p3"><span className="s1">Esta Política de privacidad se leerá junto con los Términos de uso. Esta Política de privacidad está incorporada en Condiciones de uso por referencia.</span>
                        </p> <p className="p6"><span
                            className="s1"><em>SE DEJA CONSTANCIA QUE CUALQUIER PERSONA QUE NO</em></span> <span
                            className="s1"><em>ACEPTE ESTOS TÉRMINOS Y CONDICIONES, QUE SON DE CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR</em></span>
                            <span className="s1"><em>EL SITIO Y/O LOS SERVICIOS ALLÍ OFRECIDOS.</em></span></p> <p
                            className="p4">&nbsp;</p> <p className="p3"><span className="s1">Al utilizar nuestro sitio web o servicios, usted acepta esta Política de privacidad. Si decidimos cambiar o actualizar nuestra política de privacidad, publicaremos esos cambios en la Sitio web bajo el título Política de privacidad.</span>
                        </p> <p className="p5"><span className="s1"><strong>DEFINICIONES.</strong></span></p> <p
                            className="p3"><span className="s1">Para los Términos de uso, las siguientes palabras<span
                            className="Apple-converted-space">&nbsp; </span>o frases tendrán el significado como se especifica a continuación:</span>
                        </p> <p className="p3"><span className="s1">a. <strong>Sitio web</strong>: El sitio web incluirá todos los sitios web, App y subdominios de propiedad actual o que podría ser comprado en el futuro por nosotros.</span>
                        </p> <p className="p3"><span className="s1">b. <strong>Terceros</strong>: Cualquier persona natural o jurídica que no forme parte de estos Términos de uso será considerada como Tercero.</span>
                        </p> <p className="p3"><span className="s1">c. <strong>Servicio</strong>s: Ofrecemos una gama de Servicios. Tenga en cuenta que los nuevos Servicios podrían ser aañadidos, eliminados o modificados, y por lo tanto, se solicita al usuario que visite los Sitios Web para mantenerse actualizado sobre los Servicios disponibles regularmente.</span>
                        </p> <p className="p3"><span className="s1">d. <strong>Restricción</strong>: Podemos, a nuestra discreción, restringir, interrumpir o alterar cualquier aspecto del Servicio, incluyendo, pero no limitado a;</span>
                        </p> <p className="p3"><span className="s1">i. Restricción del tiempo de disponibilidad,</span>
                        </p> <p className="p3"><span className="s1">ii. Restringir la disponibilidad y/o el alcance del Servicio para plataformas específicas (es decir, tipos de ordenadores y sistemas operativos), </span>
                        </p> <p className="p3"><span className="s1">iii. Restringir la cantidad de uso permitida,</span>
                        </p> <p className="p3"><span className="s1">iv. Restringir o poner fin a cualquier servicio del Usuario en su totalidad o parte del mismo, en cualquier tiempo, a discreción exclusiva IZEVEN sin previo aviso ni responsabilidad.</span>
                        </p> <p className="p5"><span className="s1"><strong>CONDICIONES</strong></span></p> <p
                            className="p3"><span className="s1"><strong>1. OBJETIVO</strong></span></p> <p
                            className="p3"><span className="s1">Este documento proporciona información sobre nuestra Política de privacidad en lo que se refiere a<span
                            className="Apple-converted-space">&nbsp; </span>la recopilación, uso y divulgación de la información personal. También describe a nuestros Usuarios IZEVEN; derechos para limitar la recopilaciónn, uso o divulgación de la informaciónn personal. </span>
                        </p> <p className="p3"><span className="s1">Recopilamos la información que nos proporciona a continuación solicitada para nuestros registros. Entre ellos: (Su nombre, fecha de nacimiento, país de residencia, idioma hablado, número de teléfono, id de correo electrónico, dirección de facturación, fecha de inicio y finalizaciónn de la membresía y el estatus de la misma), lo anterior cuando se registra para utilizar nuestros servicios o crear una cuenta con nosotros para que podamos identificarlo para el propósitos de tener un conocimiento personal suyo para proporcionarle<span
                            className="Apple-converted-space">&nbsp; </span>nuestros Servicios.</span></p> <p
                            className="p3"><span className="s1">Otros datos que podemos solicitarles son lo referente a: Detalles de pago; Dependiendo las pasarelas de pago que seleccione, será redirigido a un tercer enlace de parte para la recopilación de datos. Si una tarjeta de crédito está seleccionada – tarjeta número, fecha de caducidad y CVV o código de la tarjeta con la que va a realizar el pago cada vez que realiza un pago a terceros para que nosotros o el tercero<span
                            className="Apple-converted-space">&nbsp; </span>pueda procesar su pago, sin embargo, la tarjeta crédito o cualquier otro método de pago, los detalles se almacenan con el servicio de pago proveedor y no con nosotros. Por favor refiérase al</span>
                            <span className="s1">pago respectivo Proveedor de servicios Política de privacidad para más detalles Usted puede decidir no proporcionarnos su información personal. Sin embargo, si no los proporciona es posible que no tenga acceso a cierta información, servicios o productos, y/o personalizar su uso de nuestros servicios.</span>
                        </p> <p className="p3"><span className="s1">Autoriza también que, cuando visite este sitio web o utiliza nuestro software, podemos emplear herramientas y métodos automatizados (como cookies, sesiones y software de monitoreo de uso) para recopilar cierta información sobre su visita o uso de software, incluyendo:</span>
                        </p> <p className="p3"><span className="s1">a. La dirección de protocolo de Internet y el nombre de dominio utilizados por su ordenador para conectarse a la Internet;</span>
                        </p> <p className="p3"><span className="s1">b. El sistema operativo y el navegador que utiliza su ordenador, y cualquier motor de búsqueda o entrante hipervínculo utilizado para llegar a este sitio web;</span>
                        </p> <p className="p3"><span className="s1">c. La naturaleza de su uso de nuestro software o servicios, incluida la información que introduzca al utilizar nuestro software o servicios;</span>
                        </p> <p className="p3"><span className="s1">d. La fecha, hora y duración de su visita al sitio web, hora de inicio de sesión y geo- localización.</span>
                        </p> <p className="p3"><span className="s1">e. Las páginas vistas por usted, para verificación de pagos</span>
                        </p> <p className="p3"><span className="s1">La práctica descrita en esta Política de privacidad está sujeta a las leyes aplicables en los lugares en que operamos. Esto significa que solo participamos en las prácticas descritas en esta política en un país o región en particular si lo permiten las leyes de esos lugares. Por favor contáctenos si tiene preguntas sobre nuestras prácticas en su país o región. Puede contactarnos a través de nuestro Portal de atención al cliente.</span>
                        </p> <p className="p3"><span className="s1"><strong>2. CAMBIO DE POLÍTICA.</strong></span></p>
                            <p className="p3"><span className="s1">Tenga en cuenta que, debido a la naturaleza cambiante de nuestro negocio, dominio dinámico en el que operamos y cambio continuo en los servicios que ofrecemos, podría realizar cambios en la Política de privacidad de vez en cuando sin proporcionarle preaviso. Si realizamos algún cambio en nuestra Política de privacidad, es posible que le proporcionemos aviso de lo mismo mediante una notificación emergente cuando inicia sesión en su cuenta y a través de Notificación de correo electrónico.</span>
                            </p> <p className="p3"><span className="s1">En la medida en que lo permita la ley aplicable, mediante el uso de nuestros servicios después de dicho aviso o actualización, usted acepta nuestras actualizaciones de esta política.</span>
                        </p> <p className="p3"><span className="s1">Le recomendamos qué revise periodicamente esta política para obtener la información más reciente sobre nuestra Política de privacidad. Una copia de la ésta o la<span
                            className="Apple-converted-space">&nbsp; </span>más reciente están siempre disponibles en nuestro Sitio web para su aceptación de los cambios Por lo tanto, asegúrese de leer atentamente dicho aviso. Puedes continuar usando el sitio web del servicio,<span
                            className="Apple-converted-space">&nbsp; </span>aceptando siempre la nueva versión. Si no deseas aceptar los nuevos Términos y Condiciones puede cancelar su cuenta poniéndose en contacto con nosotros. </span>
                        </p> <p className="p3"><span className="s1">Tenga en cuenta que NUNCA solicitaremos a los usuarios lo siguiente: </span>
                        </p>
                            <ol className="ol1">
                                <li className="li3"><span className="s1">Dinero en efectivo.</span></li>
                                <li className="li3"><span className="s1">Solicitar ID privado de sus wallet. </span>
                                </li>
                                <li className="li3"><span className="s1">Que revelen la contraseña de su Cuenta por correo electrónico o llamadas telefónicas o cualquier otro modo de comunicación, sea electrónico o diferente</span>
                                </li>
                            </ol>
                            <p className="p3"><span
                                className="s1"><strong>3. TIPO DE INFORMACIÓN RECOPILADA Y FINALIDAD</strong></span></p>
                            <p className="p3"><span className="s1">Los usuarios también consienten que podamos tomar y usar sus fotos y videos junto a sus testimonios con fines promocionales de marketing sin necesidad de autorización separada por lo mismo </span>
                            </p> <p className="p3"><span className="s1"><strong>4. MEDIDAS DE SEGURIDAD Y PROTECCIÓN DE LA INFORMACIÓN </strong></span>
                        </p> <p className="p3"><span className="s1">Implementamos una variedad de medidas de seguridad con el fin de mantener la seguridad de su información personal cuando haga un pedido o acceda a su cuenta. Proteger la seguridad de su información personal es muy importante para nosotros, por lo tanto utilizamos tecnología de avanzada y probada que garantiza fehacientemente la protección de la información.</span>
                        </p> <p className="p3"><span className="s1"><strong>5. USO DE COOKIES</strong></span></p> <p
                            className="p3"><span className="s1"> Usamos Cookies (Las Cookies son pequeños archivos que un sitio o su servicio el proveedor transfiere al disco duro de su computadora a través de su navegador web, si lo permite), para que nuestros sitios o sistemas de proveedores de servicios reconozcan su navegador y capturar y recordar cierta información.</span>
                        </p> <p className="p3"><span className="s1">Tenga en cuenta que utilizamos cookies para los siguientes propósitos;</span>
                        </p> <p className="p3"><span className="s1">A. ayúdanos a recordar y procesar los artículos de tu carrito de compras,</span>
                        </p> <p className="p3"><span className="s1">B. realizar un seguimiento del tráfico y las actividades del sitio para que podamos ofrecer mejores experiencias y<span
                            className="Apple-converted-space">&nbsp; </span>herramientas en el futuro.</span></p> <p
                            className="p3"><span className="s1">Tenga en cuenta que podemos contratar proveedores de servicios externos para que nos ayuden a mejorar comprender a los visitantes de nuestro sitio. Estos proveedores de servicios no están autorizados a utilizar la información recopilada en nuestro nombre, excepto para ayudarnos a realizar y mejorar nuestro negocio.</span>
                        </p> <p className="p3"><span className="s1"><strong>6. NO DIVULGACIÓN DE LA INFORMACIÓN A PERSONAS EXTERNAS</strong></span>
                        </p> <p className="p3"><span className="s1">Nos comprometemos a no vender, comercializar o transferir a terceros, su<span
                            className="Apple-converted-space">&nbsp; </span>información personal, excepto, según lo dispuesto en esta Política de privacidad, cuando tengamos<span
                            className="Apple-converted-space">&nbsp; </span>que compartir su información con terceros de confianza que nos ayudan a operar nuestro sitio web, llevar a cabo nuestro negocio o brindarle servicios, siempre que esas partes acuerden mantener este información confidencial. También podemos divulgar su información cuando es exigible por la ley del país donde operamos o hacer cumplir las políticas de nuestro sitio o proteger nuestros derechos o los de otros, propiedad o seguridad.</span>
                        </p> <p className="p3"><span className="s1">Sin embargo, la información del visitante no identificable personalmente se puede proporcionar a otras partes para marketing, publicidad u otros usos. Además de esto, podemos utilizar la información de los<span
                            className="Apple-converted-space">&nbsp; </span>Usuarios para investigar y ayudar a prevenir actividades potencialmente ilegales o si así lo requiere citación, orden de registro u otros procesos legales. También podemos permitir que otros proporcionen servicios de análisis y medición de audiencia para nosotros, para publicar anuncios en nuestro nombre a través de Internet, y para rastrear e informar sobre el desempeño de esos anuncios.</span>
                        </p> <p className="p3"><span className="s1">Estas entidades pueden utilizar cookies, balizas web, SDK y otras tecnologías para identificar su dispositivo cuando visita nuestro sitio y utiliza nuestros servicios, así como cuando visita otros sitios y servicios.</span>
                        </p> <p className="p3"><span className="s1"><strong>7. ENLACES DE TERCEROS</strong></span>
                        </p> <p className="p3"><span className="s1">Ocasionalmente, a nuestra discreción, podemos incluir u ofrecer a terceros productos o servicios de nuestro sitio web. Estos sitios de terceros tienen políticas de privacidad independientes. Por lo tanto, no tenemos ninguna responsabilidad u obligación por el contenido y actividades de estos sitios vinculados. No obstante, buscamos proteger la integridad de nuestro sitio y agradecemos cualquier comentario sobre estos sitios.</span>
                        </p> <p className="p3"><span
                            className="s1"><strong>8. DIVULGACIÓN DE INFORMACIÓN PERSONAL</strong></span></p> <p
                            className="p3"><span className="s1">Compartimos con algunas de nuestras plataformas, empresas del Holding de IZEVEN, con Subsidiarias y Afiliadas, para proporcionarles nuestros productos, bienes o servicios o realizar el procesamiento de datos en nuestro nombre. Podemos proporcionar información a nuestros proveedores, consultores, socios de marketing, empresas de investigación y otros proveedores de servicios o socios comerciales. De igual manera nos vemos avocados a divulgar la información por razones legales o en conexión con reclamos o disputas por:</span>
                        </p> <p className="p3"><span className="s1">a. Según lo requiera la ley. Divulgaremos información personal cuando así lo requiera la ley o si Creemos de buena fe que dicha acción es necesaria para cumplir con un procedimiento judicial, una orden judicial o un proceso legal que se nos entregue, </span>
                        </p> <p className="p3"><span
                            className="s1">b. Proteger y defender nuestros derechos</span><span
                            className="s3">, </span></p> <p className="p3"><span className="s1">c. Proteger los derechos, la propiedad y otros intereses de nuestros usuarios u otras personas.</span>
                        </p> <p className="p3"><span className="s1">d. Con el público en general, cuando envía contenido a un foro público. Cuando usted se comunica con nosotros a través de Internet o de blogs, redes sociales, etc., si <span
                            className="Apple-converted-space">&nbsp; </span>las comunicaciones pueden ser visibles para el público.</span>
                        </p> <p className="p3"><span className="s1">e. Con su consentimiento podemos compartir la información de forma distinta a la descrita en esta política.</span>
                        </p> <p className="p3"><span
                            className="s1"><strong> 9. RETENCIÓN Y ELIMINACIÓN DE INFORMACIÓN</strong></span>
                        </p> <p className="p3"><span className="s1">Conservamos el perfil de usuario y su información durante el tiempo que mantenga su cuenta con nosotros le conservamos las transacciones, la ubicación, el uso y otra información durante siete (7) años en relación con los requisitos reglamentarios, fiscales, de seguros u otros en los lugares en los que opera. Posteriormente, eliminamos o anonimizamos dicha información de acuerdo con las leyes.</span>
                        </p> <p className="p3"><span className="s1">Los usuarios pueden solicitar la eliminación de sus cuentas en cualquier momento. A raíz de dicha solicitud, eliminamos la información que no está obligado a retener y restringir el acceso o el uso de cualquier información que se requiere mantener. Puede solicitar la eliminación de su cuenta en cualquier momento visitando nuestro portal de soporte. En determinadas circunstancias, es posible que no podamos eliminar su cuenta; como si hay un crédito pendiente en su cuenta o un reclamo no resuelto o alguna disputa. Una vez que se resuelva el problema que impide la eliminación, la haremos.</span>
                        </p> <p className="p3"><span className="s1">También podemos retener cierta información si es necesario, para sus intereses comerciales legítimos, como prevención del fraude y mejora de los usuarios<span
                            className="Apple-converted-space">&nbsp; </span>seguridad y protección. Por ejemplo, si cerramos cuenta de un usuario debido a un comportamiento inseguro o incidentes de seguridad, podemos retener cierta información sobre esa cuenta para evitar que ese usuario abra una nueva cuenta en el futuro.</span>
                        </p> <p className="p6"><span className="s1"><strong>10. TRANSFERENCIAS COMERCIALES O ESTRUCTURAS CORPORATIVAS O REESTRUCTURACIÓN</strong></span>
                        </p> <p className="p7">&nbsp;</p> <p className="p3"><span className="s1"> En el caso que pasemos por una transición empresarial, transferencia, fusión, adquisición y escisión, estructuración y reestructuración societaria, su información puede formar parte de los activos transferidos. Usted reconoce que dichas transferencias pueden hacerse y que cualquier adquirente de nuestro negocio o sus activos pueda seguir utilizando su información como se describe en esta Política de privacidad. Siempre que ocurran tales eventos, les informamos mediante notificación por correo electrónico.</span>
                        </p> <p className="p3"><span
                            className="s1"><strong>10. CAPACIDAD PARA CONTRATAR</strong></span></p> <p
                            className="p8"><span className="s1">Los servicios ofrecidos por el Sitio se encuentran disponibles para aquellos Usuarios que tengan capacidad legal para contratar, esto es adquirir derechos y contraer obligaciones. No podrán utilizar los servicios del Sitio las personas que no tengan esa capacidad, los menores de edad, por tanto al registrarse como usuario usted declara con carácter de declaración jurada que es un ser vivo humano mayor de 18 años de edad, con capacidad jurídica para contratar. </span>
                        </p> <p className="p2">&nbsp;</p> <p className="p8"><span
                            className="s1"><strong>IZEVEN</strong><span
                            className="Apple-converted-space">&nbsp; </span>no tiene la obligación ni es responsable de verificar que los Usuarios tengan<span
                            className="Apple-converted-space">&nbsp; </span>la capacidad declarada para utilizar el Sitio.</span>
                        </p> <p className="p9">&nbsp;</p> <p className="p3"><span className="s1"><strong>11. REGISTRO Y DERECHO DE LOS USUARIOS</strong></span>
                        </p> <p className="p10"><span className="s1">Para poder utilizar los servicios del Sitio, es necesario como usuario aceptar los presentes Términos y Condiciones.</span>
                        </p> <p className="p2">&nbsp;</p> <p className="p10"><span
                            className="s1"><strong>IZEVEN </strong> se reserva el derecho de rechazar cualquier solicitud de registro o de cancelar un registro previamente aceptado, sin explicar la<span
                            className="Apple-converted-space">&nbsp; </span>causa y sin que ello genere algún derecho a indemnización. También podrá inhabilitar y/o suspender temporal o permanentemente a los Usuarios que entorpezcan, dañen, o provoquen daños y perjuicios a cualquiera de los demás Usuarios y/o al Sitio o incumplir con estos Términos y Condiciones y con cualquier otra política que en el futuro establezca el sitio, lo cual será informado y publicado oportunamente.</span>
                        </p> <p className="p2">&nbsp;</p> <p className="p8"><span className="s1">Todos los Usuarios al momento de su registro en el Sitio y en todo momento mientras estén vinculados al Sitio, deberán suministrar Al sitio y/o a quien corresponda, toda la información que les soliciten a fin de que <strong>IZEVEN</strong><span
                            className="Apple-converted-space">&nbsp; </span>y/o quien corresponda pueda cumplir con la normativa relativa a la prevención de los delitos de lavado de dinero y financiamiento del terrorismo, y con la normativa fiscal y sobre origen de fondos y toda otra normativa aplicable. Si al tiempo de solicitarse el registro en el Sitio, la información suministrada por el Usuario fuera insuficiente o inconsistente a solo juicio de <strong>IZEVEN </strong> y/o de quien corresponda, éste podrá libremente rechazarla. En caso que se trate de un usuario registrado y la información fuera insuficiente o inconsistente, se podrá suspender o inhabilitar la cuenta. Ninguno de estos supuestos otorgará derecho alguno al solicitante o al usuario.</span>
                        </p> <p className="p9">&nbsp;</p> <p className="p3"><span className="s1"><strong>11. NIVELES Y TIPOS DE SUSCRIPCIONES Y RANGOS</strong></span>
                        </p> <p className="p11"><span className="s1">Ver información en:<span
                            className="Apple-converted-space">&nbsp; </span>www.izeven.com</span></p> <p
                            className="p12">&nbsp;</p> <p className="p3"><span className="s1"><strong>12. COMPROMISO Y PROHIBICIONES DEL USUARIO</strong></span>
                        </p> <p className="p13"><span className="s1">El Usuario se compromete a no efectuar operaciones ni brindar información incierta ni errónea que generen riesgos directa o indirectamente al Sitio, y a los demás Usuarios. <strong>IZEVEN</strong><span
                            className="Apple-converted-space">&nbsp; </span>podrá disponer en su caso la suspensión transitoria o definitiva de su acceso al Sitio y/o la cancelación de su registro como usuario. Los usuarios garantizan y responden por la veracidad, exactitud, autenticidad de los datos personales consignados al momento de su registro.</span>
                        </p> <p className="p14"><span className="s1">Únicamente podrá ingresar a las secciones que le sean autorizadas por sitio, por lo tanto, se abstendrá de utilizar cualquier medio para violar la seguridad y las restricciones, así como cualquier medida tecnológica. La sola intención de hacerlo, evidenciada por <strong>IZEVEN</strong>, será causal suficiente para dar por terminada la licencia o membresía e informar a las autoridades correspondientes de este hecho. </span>
                        </p> <p className="p14"><span className="s1">El USUARIO no podrá reproducir, adaptar, distribuir, alquilar, vender, otorgar licencia o ejecutar cualquier otra forma de transferencia del presente sitio ni de los demás sitios de <strong>IZEVEN</strong>, ni cualquiera de sus partes, incluyendo los códigos de programación. </span>
                        </p> <p className="p14"><span className="s1">El USUARIO no podrá reversar la ingeniería, decompilar, desensamblar, modificar, crear trabajos derivados, traducir la información o usar la información publicada en el sitio con fines comerciales o de lucro. </span>
                        </p> <p className="p14"><span className="s1">El USUARIO no podrá remover u ocultar los derechos de autor, las marcas o cualquier otra información o leyenda relacionada con la propiedad y derechos de <strong>IZEVEN</strong> o de sus proveedores de información y de servicios. El acceso no autorizado de manera escrita a los contenidos, bases de datos o servicios del presente sitio y demás sitios <strong>IZEVEN</strong>,<span
                            className="Apple-converted-space">&nbsp; </span>será considerado como una intromisión ilícita y <strong>IZEVEN</strong> podrá iniciar todas aquellas acciones legales a nivel<span
                            className="Apple-converted-space">&nbsp; </span>nacional e internacional que pueda emprender. </span>
                        </p> <p className="p3"><span className="s1"><strong>13. DERECHOS DE AUTOR</strong></span>
                        </p> <p className="p6"><span className="s1">De conformidad con las disposiciones legales vigentes (Ley 23 de 1982 y Decisión 351 del Acuerdo de Cartagena y demás que puedan considerarse complementarias), los diseños, redacciones y contenidos de cada uno de los<span
                            className="Apple-converted-space">&nbsp; </span>documentos e información aquí presentados, están protegidos por las leyes de derechos de autor, según las cuales, salvo licencia o autorización de su titular, no pueden ser fotocopiadas, reproducidas digital o físicamente, modificadas total o parcialmente o comunicadas públicamente, so pena de ser responsable civil y penalmente de infracción de derechos patrimoniales y/o morales de autor. Así mismo, los contenidos que el USUARIO realice en uso del presente sitio o de cualquier sitio de <strong>IZEVEN,</strong> son de exclusiva propiedad del USUARIO, de tal manera que cualquier autorización, permiso o reenvío de sus contenidos a terceras personas lo hace bajo su única y estricta responsabilidad. </span>
                        </p> <p className="p7">&nbsp;</p> <p className="p3"><span className="s1"><strong>14. MARCAS, PATENTES Y PROPIEDAD INTELECTUAL</strong></span>
                        </p> <p className="p6"><span className="s1">La información que aparece en el presente sitio y demás sitios de <strong>IZEVEN</strong>,<span
                            className="Apple-converted-space">&nbsp; </span>así como el diseño gráfico, la presentación de los contenidos, las marcas comerciales, las patentes y los logotipos, son propiedad exclusiva del sitio<span
                            className="Apple-converted-space">&nbsp; </span>o de terceros que han autorizado su inclusión o uso y están protegidos por las normas sobre derechos de autor nacionales y por los tratados internacionales que sobre la materia ha suscrito y ratificado la República de Colombia</span><span
                            className="s3">.</span><span className="s1"> Por lo anterior, EL USUARIO no podrá utilizar, comercializar, explotar o modificar de ninguna forma las marcas del sitio, de los proveedores de información y de servicios o de terceros, y se obliga a informar al sitio, cualquier hecho sobre el cual tenga conocimiento y que pueda considerarse como lesivo de los derechos que legalmente este tiene. </span>
                        </p> <p className="p7">&nbsp;</p> <p className="p3"><span className="s1"><strong>15. RELACIÓN CONTRACTUAL</strong></span>
                        </p> <p className="p15"><span className="s1">La vinculación del Usuario con el sitio es meramente comercial y los IPAs (Independing Promoter Associate) tienen un contrato mercantil que igualmente se puede consultar y bajar en nuestra página en el </span><span
                            className="s4">Link Ver información en:<span
                            className="Apple-converted-space">&nbsp; </span><a href="http://www.izeven.com"><span
                            className="s5">www.izeven.com</span></a> en lo relacionado a contratos IPAs</span></p>
                            <p className="p7">&nbsp;</p> <p className="p3"><span
                            className="s1"><strong>16. INDEMNIDAD</strong></span></p> <p className="p16"><span
                            className="s1">El Usuario se obliga en forma expresa e irrevocable a mantener absolutamente indemne a <strong>IZEVEN</strong><span
                            className="Apple-converted-space">&nbsp; </span>y/o sus sociedades vinculadas, accionistas, funcionarios, directivos, administradores, representantes y empleados, respecto a cualquier reclamo y/o demanda y/o sanción extrajudicial, administrativo o judicial, iniciado por otros Usuarios, terceros o por cualquier Organismo, relacionado con sus actividades en el Sitio, debido entre otros pero no limitados, al incumplimiento de estos Términos y Condiciones, o en caso de verificarse la existencia de multas y/o deudas de cualquier tipo generadas por el Usuario o la actividad del mismo realizada en o mediante el Sitio.</span>
                        </p> <p className="p16"><span className="s1">El Usuario acepta que el uso del Sitio se realiza bajo su propia, exclusiva y única responsabilidad. <strong>IZEVEN </strong> no se responsabiliza por ningún daño o perjuicio, directo o indirecto, incluyendo, sin ningún tipo de limitación, daños producidos por las pérdidas de chance, pérdidas o deterioros de la información, acciones de marketing, ni por las demoras, interrupciones y defectos que pudieran existir en las transmisiones, mediante la utilización del Sitio, así como tampoco de los virus o fallas del sistema, siempre que ello no sea imputable a <strong>IZEVEN</strong>. </span>
                        </p> <p className="p7">&nbsp;</p> <p className="p3"><span
                            className="s1"><strong>17. TARIFAS</strong></span></p> <p className="p17"><span
                            className="s1">Los Usuarios a cualquier título, sea miembro, visitante, etc., podrán registrarse en forma gratuita en <strong>IZEVEN, </strong>pero para acceder a la membresía se determina los valores indicados en la cláusula 11.</span>
                        </p> <p className="p2">&nbsp;</p> <p className="p6"><span
                            className="s1"><strong>IZEVEN</strong><span
                            className="Apple-converted-space">&nbsp; </span>podrá modificar, agregar o eliminar en forma unilateral las tarifas vigentes, debiendo notificar dicho cambio a los Usuarios en la forma establecida en la cláusula 11. Las tarifas se encuentran publicadas en el Sitio y serán facturadas y cobradas por <strong>IZEVEN </strong> conforme lo dispuesto en la ley aplicable. Las facturas están disponibles para el Usuario en el Sitio.</span>
                        </p> <p className="p4">&nbsp;</p> <p className="p3"><span className="s1"><strong>18. INFORMACIÓN ESPECIAL PARA USUARIOS DE LA UNIÓN EUROPEA (UE).</strong></span>
                        </p> <p className="p3"><span className="s1">Debido a que desde el<span
                            className="Apple-converted-space">&nbsp; </span>25 de mayo de 2018, el tratamiento de datos personales de los usuarios en la Unión Europea está sujeto a el Reglamento General de Protección de Datos de la UE,<span
                            className="Apple-converted-space">&nbsp; </span>IZEVEN, para cumplir con dicho requisito, se acoge a Términos y Condiciones, pero solo aplicable a la<span
                            className="Apple-converted-space">&nbsp; </span>Política de privacidad GDPR (<strong>El Reglamento General de Protección de Datos que es el reglamento europeo </strong>relativo a la protección de las personas físicas en lo que respecta al tratamiento de sus datos personales y a la libre circulaciónn de estos datos.). Esta Política de privacidad de RGPD se aplica, como ya se dijo, solo a los residentes en la UE según lo exige la ley. </span>
                        </p> <p className="p3"><span
                            className="s1"><strong>19. ELECCIÓN Y TRANSPARENCIA.</strong></span></p> <p
                            className="p3"><span className="s1">Proporcionamos los medios para que usted vea y controle la información que recopilamos. Puede solicitarnos que le proporcionemos una explicación, copias o La corrección de sus datos y dicha solicitud se puede realizar escribiendo a nuestro Servicio de atención al cliente.</span>
                        </p> <p className="p3"><span className="s1">Puede hacer las siguientes solicitudes:</span>
                        </p> <p className="p3"><span className="s1">i.<span
                            className="Apple-converted-space">&nbsp; </span>Explicaciones, copias y corrección de su información</span>
                        </p> <p className="p3"><span className="s1">ii. Proporcione una explicación detallada sobre la información recopilada sobre usted y cómo usa esa información.</span>
                        </p> <p className="p3"><span className="s1">iii. Reciba una copia de la información que hemos recopilado sobre usted.</span>
                        </p> <p className="p3"><span className="s1">iv. Solicitar la corrección de cualquier información inexacta que tengamos sobre usted.</span>
                        </p> <p className="p3"><span className="s1">La información solicitada en virtud de esta Cláusula se proporcionará a nuestra razonable discreción siempre que lo permitan las leyes aplicables.</span>
                        </p> <p className="p3"><span
                            className="s1"><strong>20. CONTACTO CON NOSOTROS.</strong></span></p> <p className="p8">
                            <span className="s1"> Si tiene alguna pregunta o queja con respecto a esta Política de privacidad, luego puede comunicarse con nosotros visitando nuestro Portal</span><span
                            className="s4">:<span
                            className="Apple-converted-space">&nbsp; </span>www.izeven.com</span></p> <p
                            className="p3"><span className="s1">.</span></p> <p className="p3"><span className="s1">Dpto. Jurídico.</span>
                        </p> <p className="p18"><span className="s1">Fechas de actualización: julio 1° de 2021. Hora: 10:11</span>
                        </p>
                        </div>
                        <div dangerouslySetInnerHTML={{__html: body}}></div>
                    </div>
                </Form>

            </div>
            <div className="text-center mt-4">
                <Button onClick={completeRegister} color="primary" className="w-md waves-effect waves-light">I
                    AGREE</Button>
            </div>
        </div>
    );
}
export default Terms;
