import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import Knob from "../../../../components/Knob/knob";
import { useSelector } from "react-redux";
import moment from "moment";
import { withNamespaces } from "react-i18next";

const Directs = (props) => {
  const { user } = useSelector((state) => state.user);
  const { node } = user;

  return (
    <Card>
      <CardBody>
        <h4 className="card-title mb-4">
          {props.t(moment().format("MMMM"))} {props.t("Directs")}
        </h4>

        <div id="donut-chart" className="text-center my-5">
          <Knob
            index={user.monthDirects.length}
            value={
              (100 * user.monthDirects.length) / node.current_rank.next.directs
            }
            fgColor="#11EDFC"
            lineCap="round"
            readOnly={true}
          />
        </div>

        <Row className="text-center my-1">
          <h5 className="text-secondary">{props.t("Direct User Goal")}</h5>
          <h1>
            {user.monthDirects.length} of {node.current_rank.next.directs}
          </h1>
        </Row>
      </CardBody>
    </Card>
  );
};

export default withNamespaces()(Directs);
