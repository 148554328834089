import React, { useState } from "react";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import "react-credit-cards/es/styles-compiled.css";
import InputMask from "react-input-mask";
import { useSelector } from "react-redux";

import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { axiosApi } from "../../../helpers/Api";
import authHeader from "../../../helpers/Api/auth-header";
import CreditCard from "../../../components/CreditCard/CreditCard";
import { withNamespaces } from "react-i18next";

const Pay = ({ toggle, isOpen, t }) => {
  const history = useHistory();
  const { user } = useSelector((state) => state.user);

  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [cvc, setCvc] = useState("");
  const [expiry, setExpiry] = useState("");
  const [focused, setFocused] = useState("");

  const handleInputFocus = (e) => {
    setFocused(e.target.name);
  };

  const createPaymentIntent = async () => {
    Swal.showLoading();

    const data = {
      email: user.email,
      card: {
        card_name: name,
        card_number: number,
        card_year: expiry.split("/")[1],
        card_month: expiry.split("/")[0],
        card_cvc: cvc,
      },
      payment_intent: null,
      total: (50).toString() + "00",
      description: "Monthly Renewal",
    };

    await axiosApi
      .post("api/payments/payment_intent", data, { headers: authHeader() })
      .then((response) => {
        Swal.close();
        Swal.fire({
          title: "Confirm Payment",
          text:
            "Are you sure you want to pay $" +
            (50).toString() +
            " USD with yout card ending ..." +
            number.substring(number.length - 4, number.length),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Continue",
          confirmButtonColor: "#11EDFC",
          cancelButtonText: "No, Cancel",
        }).then((result) => {
          if (result.isConfirmed) {
            pay(response.data.id, {
              ...data,
              payment_intent: response.data.id,
            });
          } else {
            pay(response.data.id, {
              ...data,
              payment_intent: response.data.id,
              cancel: true,
            });
          }
        });
      })
      .catch((error) => {
        Swal.close();
        if (error.response.status === 422) {
          Swal.fire({
            title: "Error",
            text: "You are missing some fields. Complete the fields and try again.",
            icon: "error",
            confirmButtonColor: "#11EDFC",
          });
          this.errors = error.response.data.errors;
          this.step = 1;
        } else {
          Swal.fire({
            title: "Payment Error",
            text: error.response.data.message,
            icon: "error",
            confirmButtonColor: "#11EDFC",
          });
        }
      });
  };

  const pay = async (paymentIntent, data) => {
    await Swal.showLoading();

    await axiosApi
      .post("api/payments/renewal", data, { headers: authHeader() })
      .then((response) => {
        if (response.data.status === "succeeded") {
          Swal.fire({
            title: "Payment Successful",
            text: "Congratulations, your subscription was renewed",
            icon: "success",
            confirmButtonColor: "#11EDFC",
          }).then((result) => {
            if (result.isConfirmed) {
              setTimeout(() => {
                history.push("/dashboard");
              }, 1000);
            }
          });
        } else if (response.data.status === "canceled") {
          Swal.fire({
            title: "Payment Canceled",
            text: "You canceled the order payment",
            icon: "success",
            confirmButtonColor: "#11EDFC",
          });
        } else {
          Swal.fire({
            title: "Payment Error",
            text: "There was an error with your payment, please try again.",
            icon: "error",
            confirmButtonColor: "#11EDFC",
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Payment Error",
          text: error.response.data.message,
          icon: "error",
          confirmButtonColor: "#11EDFC",
        });
      });
  };

  return (
    <Modal size="xl" toggle={toggle} isOpen={isOpen}>
      <ModalHeader charCode="Y" toggle={toggle}>
        Pay Upgrade
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col className="text-center align-items-center">
            <img
              src="https://izeven-bucket.nyc3.cdn.digitaloceanspaces.com/images/plans/renew_image.png"
              width="70%"
            />
            <h3 className="text-izeven">Plan</h3>
            <h1 className="fw-bold">
              <sup className="me-1">
                <small>$</small>
              </sup>
              50 <small>USD</small>
            </h1>
            <h5 className="text-izeven">{t("Renewal Value")}</h5>
          </Col>
          <Col>
            <CreditCard
              cvc={cvc}
              expiry={expiry}
              name={name}
              number={number}
              focused={focused}
            />

            <Form className="mt-5">
              <FormGroup>
                <Label>{t("Card Number")}</Label>
                <InputMask
                  onChange={(e) => setNumber(e.target.value)}
                  onFocus={handleInputFocus}
                  type="text"
                  name="number"
                  id="number"
                  className="form-control"
                  mask="9999 9999 9999 9999"
                  maskChar=" "
                />
              </FormGroup>

              <FormGroup>
                <Label>{t("Owner Name")}</Label>
                <Input
                  onFocus={handleInputFocus}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  name="name"
                  id="name"
                />
              </FormGroup>

              <Row>
                <Col>
                  <FormGroup>
                    <Label>{t("Expiry")}</Label>
                    <InputMask
                      onChange={(e) => setExpiry(e.target.value)}
                      onFocus={handleInputFocus}
                      type="text"
                      name="expiry"
                      id="expiry"
                      className="form-control"
                      mask="99/99"
                      maskChar={null}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>CVC</Label>
                    <InputMask
                      onChange={(e) => setCvc(e.target.value)}
                      onFocus={handleInputFocus}
                      type="text"
                      name="cvc"
                      id="cvc"
                      className="form-control"
                      mask="9999"
                      maskChar={null}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mx-4 mt-2">
                <Button onClick={createPaymentIntent} color="primary" block>
                  {t("Pay Renewal")}
                </Button>
              </Row>
            </Form>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default withNamespaces()(Pay);
