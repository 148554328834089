import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {get} from "../../../../helpers/Api";
import {useSelector} from "react-redux";
import {withNamespaces} from "react-i18next";
import '../../Styles/PlanCard.css';
import Subscribe from "./Components/Subscribe";

const Upgrade = (props) => {

    const {user} = useSelector((state) => state.user);

    const [plans, setPlans] = useState([]);
    const [plan, setPlan] = useState(null);

    const [subscribeModal, setSubscribeModal] = useState(false);
    const toggle = (plan = null) => {
        setPlan(plan);
        setSubscribeModal(!subscribeModal);
    };

    const getPlans = () => {
        get('api/plans')
            .then(response => {
                setPlans(response);
            })
    }

    useEffect(() => {
        getPlans();
    }, [])

    return (
        <Card>
            <CardBody>
                <Row className="content-center">
                    <Col md="4">
                        <Row className="font-size-18 mt-2  ">
                            <Col className="mb-3 izeven-text" md={12}>
                                <i className="fas fa-angle-double-up" style={{marginRight: '10px'}}/>
                                <span>{props.t('Upgrade Your Subscription')}</span>
                            </Col>
                        </Row>
                        <p style={{textAlign: 'justify'}}>
                            {props.t('More ben')}
                        </p>
                    </Col>
                    <Col/>
                    <Col md="7" className="text-center align-items-center">
                        <Row>
                            {
                                plans.map((plan) =>
                                    <Col xl={3} sm={6}>
                                        <Card onClick={plan.id > user.subscription_id ? () => toggle(plan) : null}
                                              className={plan.id === user.subscription_id ? 'current-plan-card' : 'plan-card'}>
                                            <CardBody>
                                                <img src={plan.image} width="100%"/>
                                                {
                                                    plan.id !== user.subscription_id ?
                                                        <>
                                                            {
                                                                plan.id > user.subscription_id ?
                                                                    <>
                                                                        <h5>{props.t('Upgrade for')}:</h5>
                                                                        <h2 className="fw-bold"><sup
                                                                            className="me-1"><small>$</small></sup>{Math.trunc(plan.cost - user.subscription.cost)}
                                                                            <small>USD</small></h2>
                                                                    </> :
                                                                    <>
                                                                        <h5>{props.t('LOWER')}</h5>
                                                                        <h2 style={{
                                                                            letterSpacing: '10px',
                                                                            marginRight: '-10px'
                                                                        }} className="fw-bold">{props.t('PLAN')}</h2>
                                                                    </>
                                                            }
                                                        </> :
                                                        <>
                                                            <h5>{props.t('CURRENT')}</h5>
                                                            <h2 className="fw-bold">{props.t('PLAN')}</h2>
                                                        </>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )
                            }
                        </Row>
                    </Col>
                </Row>
            </CardBody>
            {
                plan ? <Subscribe plan={plan} isOpen={subscribeModal} toggle={toggle}/> : null
            }
        </Card>
    )
}

export default withNamespaces()(Upgrade);
