import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import toastr from "toastr";
import { Link } from "react-router-dom";

// import images
import logodark from "../../../assets/images/logo-black-letters.png";

/*
Components.
 */
import Stepper from "../../../components/Stepper";
import RegisterCode from "./Components/RegisterCode";
import AddressInfo from "./Components/AddressInfo";
import PersonalInfo from "./Components/PersonalInfo";
import LoginInfo from "./Components/LoginInfo";
import UploadPicture from "./Components/UploadPicture";
import Terms from "./Components/Terms";
import RegisterFinished from "./Components/RegisterFinished";
import LocationInfo from "./Components/LocationInfo";
import { post } from "../../../helpers/Api";

const Register = (props) => {
  const [data, setData] = useState({});

  const submit = () => {
    data.code = data.invitation.code;
    data.sponsor_id = data.invitation.invited_by;

    post("api/register", data)
      .then((response) => {
        setStep(step + 1);
      })
      .catch((error) => {
        toastr.error("Something went wrong, try again later", "Server Error");
      });
  };

  const handleChange = (key, value) => {
    let temp = {
      ...data,
    };

    temp[key] = value;

    setData(temp);
  };

  const [step, setStep] = useState(0);
  const steps = [
    {
      component: (
        <RegisterCode
          handleChange={handleChange}
          setStep={setStep}
          step={step}
        />
      ),
    },
    {
      component: (
        <PersonalInfo
          handleChange={handleChange}
          step={step}
          setStep={setStep}
        />
      ),
    },
    {
      component: (
        <LocationInfo
          handleChange={handleChange}
          step={step}
          setStep={setStep}
        />
      ),
    },
    {
      component: (
        <AddressInfo
          handleChange={handleChange}
          step={step}
          setStep={setStep}
        />
      ),
    },
    {
      component: (
        <LoginInfo handleChange={handleChange} step={step} setStep={setStep} />
      ),
    },
    {
      component: (
        <UploadPicture
          handleChange={handleChange}
          step={step}
          setStep={setStep}
        />
      ),
    },
    {
      component: <Terms submit={submit} step={step} setStep={setStep} />,
    },
    {
      component: <RegisterFinished step={step} setStep={setStep} />,
    },
  ];

  useEffect(() => {
    document.body.classList.add("auth-body-bg");
  }, []);

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div className="mb-5">
                        <div className="text-center ">
                          <div className="">
                            <Link to="#" className="logo mb-4">
                              <img
                                src={logodark}
                                height="80"
                                alt="logo"
                                style={{ marginBottom: "30px" }}
                              />
                            </Link>
                          </div>
                        </div>
                        <Stepper step={step} setStep={setStep} steps={steps} />
                        <div className="mt-5 text-center">
                          <p>
                            ¿Ya tiene una cuenta?{" "}
                            <Link to="/login" className="fw-medium ">
                              {" "}
                              Inicie sesión
                            </Link>
                          </p>
                          <p>
                            © {new Date().getFullYear()}{" "}
                            <a href="https://izeven.com/" target="_blank">
                              iZeven LLC
                            </a>
                            .
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="authentication-bg">
                <div className="bg-overlay" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register;
