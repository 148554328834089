import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Input, Label, Row } from "reactstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import logodark from "../../assets/images/logo-dark.png";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { axiosApi, post } from "../../helpers/Api";
import authHeader from "../../helpers/Api/auth-header";
import { withNamespaces } from "react-i18next";

const InvitePage = (props) => {
  const { id } = useParams();
  const history = useHistory();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [host, setHost] = useState("");

  const getHostData = () => {
    axiosApi
      .get("api/network/user/" + id)
      .then((response) => {
        setHost(response.data.name);
      })
      .catch((error) => console.log(error));
  };

  const inviteUser = (e) => {
    e.preventDefault();

    const data = {
      name: name,
      email: email,
      phone: phone,
      invited_by: id,
    };

    post("api/public/invite", data)
      .then((response) => {
        history.push("/register");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getHostData();
  }, []);

  return (
    <>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div className="mb-5">
                        <div className="text-center ">
                          <div className="">
                            <Link to="#" className="logo mb-4">
                              <img
                                src={logodark}
                                height="80"
                                alt="logo"
                                style={{ marginBottom: "30px" }}
                              />
                            </Link>
                          </div>

                          <h5>
                            {props.t("You are a guest of")} {host}
                          </h5>
                          <h4 className="font-size-18 mt-4">
                            {props.t("Welcome!")}
                          </h4>
                          <p className="text-muted">
                            {props.t("This invitational")}.
                          </p>
                        </div>

                        <div className="p-2 mt-5">
                          <Form className="form-horizontal">
                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-user-2-line auti-custom-input-icon" />
                              <Label htmlFor="username">
                                {props.t("Name")}
                              </Label>
                              <Input
                                onChange={(e) => setName(e.target.value)}
                                name="username"
                                type="text"
                                className="form-control"
                                id="username"
                                placeholder="Enter your name"
                              />
                            </div>

                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-mail-line auti-custom-input-icon" />
                              <Label htmlFor="email">{props.t("Email")}</Label>
                              <Input
                                onChange={(e) => setEmail(e.target.value)}
                                name="email"
                                type="text"
                                className="form-control"
                                id="email"
                                placeholder="Enter your email"
                              />
                            </div>

                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-smartphone-line auti-custom-input-icon" />
                              <Label htmlFor="phone">{props.t("Phone")}</Label>
                              <Input
                                onChange={(e) => setPhone(e.target.value)}
                                name="phone"
                                type="text"
                                className="form-control"
                                id="phone"
                                placeholder="Enter your phone"
                              />
                            </div>

                            <div className="mt-4 text-center">
                              <Button
                                onClick={inviteUser}
                                color="primary"
                                className="w-md waves-effect waves-light"
                                type="submit"
                              >
                                {props.t("Send Code")}
                              </Button>
                            </div>
                          </Form>
                        </div>

                        <div className="mt-5 text-center">
                          <p>
                            {" "}
                            {props.t("Already account")}{" "}
                            <Link to="/login" className="fw-medium ">
                              {" "}
                              {props.t("Login")}
                            </Link>
                          </p>
                          <p>
                            © {new Date().getFullYear()}{" "}
                            <a href="https://izeven.com/" target="_blank">
                              iZeven LLC
                            </a>
                            .
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="authentication-bg">
                <div className="bg-overlay" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withNamespaces()(InvitePage);
