import React, { Component, useEffect, useState } from "react";
import { Container, Row, Col, Label, Card, CardBody, Button } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import "../../assets/css/pricing.css";
import { get } from "../../helpers/Api";
import Subscribe from "./Components/Subscribe";
import Cripto from "./Components/Cripto";

import { withNamespaces } from "react-i18next";

const Plans = (props) => {
  const [plans, setPlans] = useState([]);
  const [plan, setPlan] = useState(null);

  const [criptoModal, setCriptoModal] = useState(false);
  const toggleCriptoModal = () => {
    setCriptoModal(!criptoModal);
  };

  const [subscribeModal, setSubscribeModal] = useState(false);
  const toggle = (plan = null) => {
    setPlan(plan);
    setSubscribeModal(!subscribeModal);
  };

  const getPlans = () => {
    get("api/plans").then((response) => {
      setPlans(response);
    });
  };

  let breadcrumbItems = [
    { title: "iZeven", link: "/" },
    { title: props.t("Plans"), link: "#" },
  ];

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Plans")}
            breadcrumbItems={breadcrumbItems}
          />

          <Row className="justify-content-center">
            <Col lg={5}>
              <div className="text-center mb-5">
                <h1 className="text-izeven">{props.t("Choose Your Plan")}</h1>
                <h3 className="fw-light text-muted mb-4">
                  {props.t("Make life")}.
                </h3>
              </div>
            </Col>
          </Row>

          <Row>
            {plans.map((plan) => (
              <Col xl={3} sm={6}>
                <Card className="pricing-box">
                  <CardBody className="text-center">
                    <img src={plan.image} width="70%" />

                    <h3 className="text-izeven">{plan.name} Plan</h3>
                    <h1 className="fw-bold">
                      <sup className="me-1">
                        <small>$</small>
                      </sup>
                      {Math.trunc(plan.cost)} <small>USD</small>
                    </h1>

                    <div className="mt-5 mb-3">
                      <Button
                        onClick={() => toggle(plan)}
                        className="btn-buy btn-rounded"
                        block
                        size="lg"
                        color="primary"
                      >
                        <i className="fas fa-credit-card" />{" "}
                        {props.t("Buy Now")}
                      </Button>
                      <p className="mt-4">
                        {props.t("Monthly Renewal of")} $50 <small>USD</small>
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>

          <Row className="justify-content-center">
            <Button
              onClick={() => toggleCriptoModal()}
              className="btn-buy btn-rounded"
              block
              color="primary"
            >
              ¿Quiere pagar con criptomonedas? Haga clic aquí.
            </Button>
          </Row>
        </Container>
      </div>

      {plan ? (
        <Subscribe plan={plan} isOpen={subscribeModal} toggle={toggle} />
      ) : null}

      <Cripto isOpen={criptoModal} toggle={toggleCriptoModal} />
    </>
  );
};

export default withNamespaces()(Plans);
