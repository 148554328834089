import React, {useState, useEffect} from "react";
import {Col, Row} from "reactstrap";

const Step = ({active, children, isLast}) => {
    return (
        <Col md={12}>
            {
                active ?
                    <div>
                        <div className="px-5 py-3" style={{width: '100%'}}>
                            {children}
                        </div>
                    </div> :
                    !isLast ?
                        <div /> : null
            }
        </Col>
    )
}

export default Step;
