import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, ButtonGroup, Button } from "reactstrap";
import { withNamespaces } from "react-i18next";
import ReactApexChart from "react-apexcharts";
import "../../../scss/dashboar.scss";

const Side = ({ side, data, points, users, t }) => {
  const formatData = () => {
    let days = [];

    if (data.length) {
      for (let i = 1; i <= 7; i++) {
        let val = data.find((element) => {
          return element.day === i;
        });
        days.push(val ? val.points : 0);
      }

      setSeries([
        {
          name: "Points",
          type: "line",
          data: days,
        },
      ]);
    }
  };

  useEffect(() => {
    formatData();
  }, [data]);

  const [series, setSeries] = useState([
    {
      name: "Points",
      type: "line",
      data: [0, 0, 0, 0, 0, 0, 0],
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },

    legend: {
      show: false,
    },
    colors: ["#11EDFC"],
    labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  });

  return (
    <>
      <div className="float-end">
        <i
          className={
            side === "Left"
              ? "fas text-izeven fa-arrow-left"
              : "fas text-izeven fa-arrow-right"
          }
        />
      </div>
      <h4 className="card-title text-izeven">{t(side + " Side")}</h4>
      <h5>
        {" "}
        {t("Users in side")}{" "}
        <small className="text-secondary">{t("All Time")}</small>: {users}{" "}
      </h5>
      <hr />
      <Row className="text-center">
        <Col className="col-6">
          <p className="mb-1">Volumen en el Ciclo</p>
          <h5>
            {points.cicle} {t("points")}
          </h5>
        </Col>
        <Col className="col-6">
          <p className="mb-1">Volumen en el Mes</p>
          <h5>
            {points.month} {t("points")}
          </h5>
        </Col>
      </Row>
      {/* <hr />
      <div>
        <div id="line-column-chart" className="apex-charts" dir="ltr">
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            height="280"
          />
        </div>
      </div> */}
    </>
  );
};

export default withNamespaces()(Side);
