import React, {useEffect, useState} from "react";
import {Button, Form} from "reactstrap";

import '../Styles/Register.css';

import profilePic from "../../../../assets/images/profilepic.png";

const UploadPicture = (props) => {

    const [picture, setPicture] = useState(profilePic);
    const [file, setFile] = useState(null);

    const uploadPicture = () => {
        document.getElementById('profile_pic').click();
    };

    const onFileChange = (e) => {
        const file = e.target.files[0]
        docToBase64(file)
            .then(response => {
                setFile(response);
                setPicture(URL.createObjectURL(file));
                props.handleChange('picture', response);
            })
    }

    const docToBase64 = (file) => {
        return new Promise(resolve => {
            let baseURL = ''
            const reader = new FileReader()

            reader.readAsDataURL(file)

            reader.onload = () => {
                baseURL = reader.result
                resolve(baseURL)
            }
        })
    }

    useEffect(() => {
        document.body.classList.add("auth-body-bg");
    }, []);

    return (
        <div>
            <div className="izeven-text font-size-18 mb-4">
                <i onClick={() => props.setStep(props.step - 1)}
                   className="fas fa-arrow-left  text-center izeven-text"/> Back
            </div>
            <h4 className="font-size-18 mt-3 text-center izeven-text">UPLOAD PICTURE (OPTIONAL)</h4>
            <p className="text-center text-secondary">Let us get to know you...</p>
            <div className=" mt-3 text-center">

                <img alt="profile picture" className="profile-image" height="180" src={picture}/>

                <div className="text-center mb-4">
                    <input id="profile_pic" type="file" accept="image/*" hidden onChange={onFileChange} />
                    <Button onClick={uploadPicture} outline color="primary" className="w-md waves-effect waves-light" type="submit">
                        Upload Profile Pic
                    </Button>
                </div>

                <div className="text-center ">
                    <Button onClick={() => props.setStep(props.step + 1)} color="primary"
                            className="w-md waves-effect waves-light" type="submit">Next</Button>
                </div>

            </div>
        </div>
    );
}

export default UploadPicture;
