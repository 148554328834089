import React, {useEffect, useState} from "react";
import {Row, Card, CardBody, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Table} from "reactstrap";
import {Link} from "react-router-dom";
import {withNamespaces} from "react-i18next";
import Vector from "../Vectormap";
import {axiosApi} from "../../../../helpers/Api";
import authHeader from "../../../../helpers/Api/auth-header";

const Locations = (props) => {

    const [data, setData] = useState([]);
    const [regions, setRegions] = useState([]);

    const getDataOfRegion = async (code) => {
        let data = await axiosApi.get('https://restcountries.com/v3.1/alpha/' + code)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error);
            })

        return data;
    }

    const getRegions = async () => {
        await axiosApi.get('api/stats/world', {headers: authHeader()})
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                console.log(error);
            })
    }

    const formatRegions = () => {
        let regs = [];
        data.forEach(region => {
            regs.push(region.country);
        });
        setRegions(regs);
    }

    useEffect(() => {
        getRegions();
    }, []);

    useEffect(() => {
        formatRegions();
    }, [data]);

    return (
        <Card>
            <CardBody>
                <div className="mb-5">
                    <h4 className="card-title">{props.t('iZeven Users')}</h4>
                    <p>{props.t('Map world')}</p>
                </div>

                <Row>
                    <Col md={12}>
                        <div id="usa-vectormap" style={{height: "310px"}}>
                            <Vector
                                regions={regions}
                                value="world_mill"
                                color="#e8ecf4"
                            />
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>)
}

export default withNamespaces()(Locations);
