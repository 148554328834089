import React, {useState} from 'react';
import {withNamespaces} from "react-i18next";
import { Link } from "react-router-dom";

const IUP = ({t}) => {
    return (
        <>
            <Link to="/iup" className="dropdown-icon-item">
                <img src="https://izeven.sfo3.cdn.digitaloceanspaces.com/General/Logos/IUP/iup_black_yellow.png"/>
                <span style={{color: '#FFD729'}}>I-UP</span>
            </Link>
        </>
    )
}

export default withNamespaces()(IUP);
