import React, { Component, useState } from "react";
import { Row, Col, Alert, Button, Container, Label } from "reactstrap";

// Redux
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// import images
import logodark from "../../assets/images/logo-sm-dark.png";
import { axiosApi } from "../../helpers/Api";
import toastr from "toastr";

const ForgetPasswordPage = () => {
  const [email, setEmail] = useState("");

  const resetPassword = async () => {
    await axiosApi
      .post("api/password_reset", { email: email })
      .then((response) => {
        setEmail("");
        toastr.success("Password reset link sent.", "Success");
      })
      .catch((error) => {
        if (error.response) {
          toastr.error(error.response.data.message, "Error");
        } else {
          toastr.error(
            "Something went wrong, try again later.",
            "Server Error"
          );
        }
      });
  };

  return (
    <>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="/" className="logo">
                              <img src={logodark} height="50" alt="logo" />
                            </Link>
                          </div>

                          <h4 className="font-size-18 mt-4">
                            Restablecer Contraseña
                          </h4>
                          <p className="text-muted">
                            Recupera tu acceso a iZeven.
                          </p>
                        </div>

                        <div className="p-2 mt-5">
                          <AvForm className="form-horizontal">
                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-mail-line auti-custom-input-icon"></i>
                              <Label htmlFor="useremail">
                                Correo Electrónico
                              </Label>
                              <AvField
                                name="useremail"
                                type="email"
                                value={email}
                                className="form-control"
                                id="useremail"
                                placeholder="..."
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>

                            <div className="mt-4 text-center">
                              <Button
                                onClick={resetPassword}
                                color="primary"
                                className="w-md waves-effect waves-light"
                              >
                                Recuperar
                              </Button>
                            </div>
                          </AvForm>
                        </div>

                        <div className="mt-5 text-center">
                          <p>
                            ¿No tienes este problema?{" "}
                            <Link to="/login" className="fw-medium ">
                              {" "}
                              Ingresa{" "}
                            </Link>
                          </p>
                          <p>
                            © {new Date().getFullYear()}{" "}
                            <a href="https://izeven.com/" target="_blank">
                              iZeven LLC
                            </a>
                            .
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="authentication-bg">
                <div className="bg-overlay"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withRouter(ForgetPasswordPage);
