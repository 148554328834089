import React, {useState, useEffect} from "react";
import Step from "./Components/Step";

/**
 * @param step The screens to render in the step.
 * @param step The index of stepper
 * @returns {JSX.Element}
 * @constructor
 */
const Stepper = ({ steps, step }) => {
    return steps.map((component, index) =>
        <Step active={step === index} children={component.component} isLast={index + 1 === steps.length} key={index}/>
    );
}

export default Stepper;
