import React from "react";
import { Card, CardBody, Progress } from "reactstrap";
import { useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
const ProgressRank = (props) => {
  const { user } = useSelector((state) => state.user);

  const { node } = user;

  return (
    <Card>
      <CardBody>
        <h4 className="text-izeven">
          {props.t("You are on the way to")} {node.current_rank.next.name}...
        </h4>
        <p>
          {props.t("You have a progress of")}{" "}
          <strong className="text-izeven">{node.percent}%</strong>{" "}
          {props.t("to reach")} {node.current_rank.next.name} {props.t("rank")}.
        </p>
        <hr />
        <Progress
          value={node.percent}
          color="primary"
          style={{ height: "30px", fontSize: "20px", fontWeight: "bold" }}
          animated
        >
          {node.percent} %
        </Progress>
      </CardBody>
    </Card>
  );
};

export default withNamespaces()(ProgressRank);
