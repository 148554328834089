import React, { Component, useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  InputGroup,
  Input,
  Button,
  FormGroup,
  Form,
} from "reactstrap";
import toastr from "toastr";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Tree from "./Components/Tree";
import { get, post } from "../../helpers/Api";
import { useSelector } from "react-redux";
import authHeader from "../../helpers/Api/auth-header";

import { withNamespaces } from "react-i18next";

const Network = (props) => {
  const { user } = useSelector((state) => state.user);

  const [currentNode, setCurrentNode] = useState(null);
  const [parentNode, setParentNode] = useState(null);

  const [nodes, setNodes] = useState([]);

  const [email, setEmail] = useState("");

  const host = window.location.protocol + "//" + window.location.host;

  let breadcrumbItems = [
    { title: "iZeven", link: "/" },
    { title: props.t("Network"), link: "#" },
  ];

  const copylink = () => {
    const copyText = document.getElementById("invite-link");

    copyText.select();
    copyText.setSelectionRange(0, 99999);

    navigator.clipboard.writeText(copyText.value);
  };

  const getNetwork = async (id = null, isNodeId = false) => {
    const url = `api/network/${isNodeId ? "node/" : ""}${id ? id : user.id}`;

    await get(url, { headers: authHeader() }).then((response) => {
      setNodes([
        ...response.master_user,
        ...response.network_1,
        ...response.network_2,
        ...response.network_3,
      ]);
    });
  };

  const searchInNetwork = async () => {
    if (email === "" || email === null) {
      getNetwork();
    }

    await post(
      `api/network/search`,
      { email: email },
      { headers: authHeader() }
    ).then((response) => {
      if (response.status === "error") {
        toastr.error(response.message);
        return;
      }

      setNodes([
        ...response.master_user,
        ...response.network_1,
        ...response.network_2,
        ...response.network_3,
      ]);
    });
  };

  const inviteUser = (data) => {
    post("api/network/invite", data, { headers: authHeader() }).then(
      (response) => {
        console.log(response);
        getNetwork();
      }
    );
  };

  useEffect(() => {
    setCurrentNode(nodes[0]);
  }, [nodes]);

  useEffect(() => {
    getNetwork();
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("My Network")}
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <Card>
              <Row className="align-items-center py-1">
                <Col md={4}>
                  <nav className="" aria-label="breadcrumb">
                    <ol className="breadcrumb" style={{ marginBottom: "0px" }}>
                      <li className="breadcrumb-item" aria-current="page">
                        {currentNode && currentNode.user_id !== user.id ? (
                          <a onClick={() => getNetwork()}>
                            {props.t("My Network")}
                          </a>
                        ) : (
                          "My Network"
                        )}
                      </li>
                      {currentNode && currentNode.user_id !== user.id ? (
                        <li
                          className="active breadcrumb-item"
                          aria-current="page"
                        >
                          <a onClick={() => getNetwork(currentNode.pid, true)}>
                            {props.t("Parent")}
                          </a>
                        </li>
                      ) : null}
                      {currentNode && currentNode.user_id !== user.id ? (
                        <li
                          className="active breadcrumb-item"
                          aria-current="page"
                        >
                          <a onClick={() => getNetwork(currentNode.pid, true)}>
                            {props.t("Network of")} {currentNode.name}
                          </a>
                        </li>
                      ) : null}
                    </ol>
                  </nav>
                </Col>
                <Col md={4}>
                  <Row className="align-items-center">
                    <Col className="" md={3}>
                      <Label className="mb-0">{props.t("Invite Link")}</Label>
                    </Col>
                    <Col md={9}>
                      <InputGroup>
                        <Input
                          id="invite-link"
                          value={host + "/inviteby/" + user.id}
                        />
                        <Button onClick={copylink} color="primary">
                          <i className="fa fa-copy" />
                        </Button>
                      </InputGroup>
                    </Col>
                  </Row>
                </Col>
                <Col md={4}>
                  <Row className="align-items-center">
                    <Col md={12}>
                      <InputGroup>
                        <Input
                          id="user-email"
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Search by user email"
                        />
                        <Button onClick={searchInNetwork} color="primary">
                          <i className="fa fa-search" />
                        </Button>
                      </InputGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Row>

          <Tree nodes={nodes} getNetwork={getNetwork} inviteUser={inviteUser} />
        </Container>
      </div>
    </>
  );
};

export default withNamespaces()(Network);
