import React, {useEffect, useState} from "react";
import {Card, Table, Row, Col, Pagination, PaginationItem, PaginationLink, Label, Input, FormGroup} from "reactstrap";
import "../../../assets/css/pagination.css";
import { withNamespaces } from "react-i18next";
import ISelect from "../../../components/Select";
const Transactions = ({transactions = [], t}) => {

    const bonus = [
        {
            label: 'BONO DIRECTO',
            value: 'BONO DIRECTO'
        },
        {
            label: 'BONO DIRECTO - UPGRADE',
            value: 'BONO DIRECTO - UPGRADE'
        },
        {
            label: 'BONO DE RED',
            value: 'BONO DE RED'
        },
        {
            label: 'BONO DE GENERACION',
            value: 'BONO DE GENERACION'
        }
    ]

    return (
        <Card>
            <div className="px-4 py-4">
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <Label>{t('Filter Bonus')}</Label>
                            <ISelect options={bonus} placeholder="Select bonus type..." />
                        </FormGroup>
                    </Col>
                </Row>
                <Table responsive>
                    <thead>
                    <tr>
                        <th className="izeven-text">{t('DATE')}</th>
                        <th className="izeven-text">{t('Description')}</th>
                        <th className="izeven-text">{t('Additional Info')}</th>
                        <th className="izeven-text">{t('Amount')}</th>
                        <th className="izeven-text">{t('Status')}</th>
                        <th className="izeven-text">Balance</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        transactions.map(transaction => (
                            <tr>
                                <th>{transaction.date}</th>
                                <td>{transaction.description}</td>
                                <td>{transaction.additional_info}</td>
                                {transaction.type > -1 ? <td className="text-success"> $ {transaction.amount}</td> :
                                    <td className="text-danger">$ {transaction.amount}</td>}
                                <td>{transaction.status}</td>
                                <td>{transaction.balance}</td>
                            </tr>
                        ))
                    }
                    </tbody>
                </Table>
            </div>

        </Card>
    );
}
export default withNamespaces() (Transactions);
