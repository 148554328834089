import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register/Register";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

import Wallet from "../pages/Wallet";
import Profile from "../pages/Profile";
import Network from "../pages/Network";
import Generation from "../pages/Generation";
import Plans from "../pages/Plans";
import InvitePage from "../pages/InvitePage/InvitePage";
import Settings from "../pages/Settings/Settings";
import Ipa from "../pages/Ipa/Ipa";
import Renewal from "../pages/Renewal/Renewal";
import ChangePassword from "../pages/Authentication/ChangePassword";
import ForgetPassword from "../pages/Authentication/ForgetPassword";
import IUPPage from "../pages/IUP";

const authProtectedRoutes = [
	{ path: "/dashboard", component: Dashboard },
	{ path:	"/wallet", component: Wallet},
	{ path: "/profile", component: Profile },
	{ path: "/network", component: Network },
	{ path: "/generation", component: Generation },
	{path: "/plans", component: Plans},
	{path: "/ipa", component: Ipa},
	{path: "/renewal", component: Renewal},
	{path: "/settings", component: Settings},
	{path: "/iup", component: IUPPage},
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/inviteby/:id", component: InvitePage },
	{ path: "/forgot-password", component: ForgetPassword },
	{ path: "/change-password/:code", component: ChangePassword},
	{ path: "/register", component: Register },
];

export { authProtectedRoutes, publicRoutes };
