import React, {useState, useEffect} from "react";
import {Input, Button, Label, Form, FormGroup} from "reactstrap";

import {getAllCountries, getCountryStates, getStateCities} from '../../../../utils/CountryStateCityApi';


import '../Styles/Register.css'
import ISelect from "../../../../components/Select";
import toastr from "toastr";

const LocationInfo = (props) => {

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const [country, setCountry] = useState(null);
    const [state, setState] = useState(null);
    const [city, setCity] = useState(null);

    const getCountries = () => {
        getAllCountries()
            .then(response => setCountries(response));
    }

    const getStates = () => {
        getCountryStates(country)
            .then(response => setStates(response));
    }

    const getCities = () => {
        getStateCities(country, state)
            .then(response => setCities(response))
    }

    useEffect(() => {
        getCountries();
        document.body.classList.add("auth-body-bg");
    }, []);

    useEffect(() => {
        if (country) {
            getStates();
        }
    }, [country]);

    useEffect(() => {
        if (state) {
            getCities();
        }
    }, [state]);

    const handleChange = (e, setState, id = null) => {
        setState(e.target ? e.target.value : e.value);
        props.handleChange(id ? id : e.target.id, e.target ? e.target.value : e.value);
    }

    const validate = () => {
        return country && state && city;
    }

    const next = () => {
        if (validate()) {
            props.setStep(props.step + 1)
        } else {
            toastr.error('There are no fields to be entered', 'Error');
        }
    }

    return (
        <div>
            <div className="izeven-text font-size-18 mb-4">
                <i onClick={() => props.setStep(props.step - 1)}
                   className="fas fa-arrow-left  text-center izeven-text"/> Back
            </div>

            <h4 className="text-center izeven-text">LOCATION INFO</h4>
            <p className="text-center text-secondary">Let us get to know you...</p>

            <div>
                <Form className="form-horizontal mb-2">
                    <FormGroup>
                        <Label>Country</Label>
                        <ISelect value={country} onChange={(e) => handleChange(e, setCountry, 'country')} options={countries} placeholder="Select your Country..." />
                    </FormGroup>

                    <FormGroup>
                        <Label>State</Label>
                        <ISelect value={state} onChange={(e) => handleChange(e, setState, 'state')} options={states} placeholder={ country ? 'Select your State' : 'First select your Country' } disabled={!country} />
                    </FormGroup>

                    <FormGroup>
                        <Label>City</Label>
                        <ISelect value={city} onChange={(e) => handleChange(e, setCity, 'city')} options={cities} placeholder={ country ? 'Select your City' : 'First select your State' } disabled={!state} />
                    </FormGroup>

                    <div className="text-center">
                        <Button onClick={next} color="primary" className="w-md waves-effect waves-light">Next</Button>
                    </div>
                </Form>
            </div>
        </div>
    );
}

export default LocationInfo;
