import React, { Component, useState } from "react";
import { Row, Col, Alert, Button, Container, Label } from "reactstrap";

// Redux
import { withRouter, Link, useParams, useHistory } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// import images
import logodark from "../../assets/images/logo-dark.png";
import { axiosApi } from "../../helpers/Api";
import toastr from "toastr";

const ChangePasswordPage = () => {
  const { code } = useParams();

  const history = useHistory();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const changePassword = async () => {
    if (password === confirmPassword) {
      await axiosApi
        .post("api/change_password", { password: password, code: code })
        .then((response) => {
          toastr.success("Password changed with success.", "Success");
          setTimeout(() => {
            history.push("/login");
          }, 2000);
        })
        .catch((error) => {
          toastr.error(
            "Something went wrong, try again later.",
            "Server Error"
          );
        });
    } else {
      toastr.error("Passwords do not match", "Error");
    }
  };

  return (
    <>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="/" className="logo">
                              <img src={logodark} height="80" alt="logo" />
                            </Link>
                          </div>

                          <h4 className="font-size-18 mt-4">
                            Cambie su contraseña
                          </h4>
                          <p className="text-muted">
                            Establece tu nueva contraseña para iniciar sesión en
                            iZeven.
                          </p>
                        </div>

                        <div className="p-2 mt-5">
                          <AvForm className="form-horizontal">
                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-lock-line auti-custom-input-icon" />
                              <Label htmlFor="password">Contraseña</Label>
                              <AvField
                                name="password"
                                type="password"
                                className="form-control"
                                id="password"
                                e
                                placeholder="Ingresa tu contraseña..."
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>

                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-lock-line auti-custom-input-icon" />
                              <Label htmlFor="password_confirm">
                                Confirmar Contraseña
                              </Label>
                              <AvField
                                name="password_confirm"
                                type="password"
                                className="form-control"
                                id="password_confirm"
                                placeholder="Vuele a escribir tu contraseña..."
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                              />
                            </div>

                            <div className="mt-4 text-center">
                              <Button
                                onClick={changePassword}
                                color="primary"
                                className="w-md waves-effect waves-light"
                              >
                                Cambiar Contraseña
                              </Button>
                            </div>
                          </AvForm>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="authentication-bg">
                <div className="bg-overlay"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withRouter(ChangePasswordPage);
