import React, {useRef} from "react";
import { Card, Row, Col, Label } from "reactstrap";
import './style.css';

import {useDispatch, useSelector} from "react-redux";
import convertToBase64 from "../../../../helpers/Convert/base64";
import {checkAuth} from "../../../../features/userSlice";
import {axiosApi} from "../../../../helpers/Api";
import authHeader from "../../../../helpers/Api/auth-header";
import toastr from "toastr";

const ProfileBar = () => {

    const { user } = useSelector((state) => state.user);

    const pictureRef = useRef();

    const dispatch = useDispatch();

    const handleChange = (e) => {
        const file = e.target.files[0];
        convertToBase64(file)
            .then(response => {
                update(response).then(() => dispatch(checkAuth()));
            })
    }

    const update = async (file) => {
        await axiosApi.post('api/profile/pic', {picture: file}, {headers: authHeader()})
            .then(response => {
                toastr.success('Profile picture updated.', 'Success');
            })
            .catch(error => {
                console.log(error);
            })
    }

    return (
        <React.Fragment>
            <Row className=" text-center">
                <Col md={12}>
                    <Row className="content-center">
                        <Col>
                            <img className="profile-pic mt-4" src={ user.profile_picture } />
                        </Col>
                    </Row>
                    <a onClick={() => pictureRef.current.click()} className="mt-3 btn btn-primary"><i className="fa fa-camera"/> Change Photo</a>
                    <input type="file" ref={pictureRef} accept="image/png, image/gif, image/jpeg" onChange={handleChange} hidden/>

                    <h1 className="mt-4">{ user.name }</h1>
                    <h5 className="mb-5 izeven-text">{ user.subscription ?  user.subscription.name : 'No Suscribed' }</h5>
                </Col>
            </Row>

        </React.Fragment>
    );
}

export default ProfileBar;
