import React, { Component, useEffect, useState } from "react";
import { Container, Row, Col, Label, Card, CardBody } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkAuth } from "../../features/userSlice";
import Metrics from "./Components/Metrics";
import Sides from "./Components/Sides";
import Locations from "./Components/Locations";
import Directs from "./Components/Directs";
import Countdown from "./Components/Countdown";
import Rank from "./Components/Rank";
import ProgressRank from "./Components/ProgressRank";
import { withNamespaces } from "react-i18next";
import background from "../../assets/images/web.png";
import MonthlyCountdown from "./Components/MonthlyCountdown";
import CicleCountdown from "./Components/CicleCountdown";
import banner from "../../assets/images/BANNER IZEVEN.png";

const Dashboard = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(checkAuth())
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        props.history.push("/login");
      });
  }, []);

  useEffect(() => {
    if (!user.subscription) {
      history.push("/plans");
    } else if (user.expired) {
      history.push("/renewal");
    } else if (!user.ipa) {
      history.push("/ipa");
    }
  }, []);

  let breadcrumbItems = [
    { title: "iZeven", link: "/" },
    { title: props.t("Dashboard"), link: "#" },
  ];

  return (
    <>
      <div className="page-content">
        {loading ? (
          <div id="preloader">
            <div id="status">
              <div className="spinner">
                <i className="ri-loader-line spin-icon" />
              </div>
            </div>
          </div>
        ) : (
          <Container fluid>
            <Breadcrumbs
              title={props.t("Dashboard")}
              breadcrumbItems={breadcrumbItems}
            />

            <Row className="my-4 mx-2">
              <img
                className="p-0"
                src="https://izeven-bucket.nyc3.cdn.digitaloceanspaces.com/images/dashboard_banner.png"
                width="100%"
              />
            </Row>

            <Row>
              <Col md={12}>
                <Rank />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <CicleCountdown />
              </Col>
              <Col md={6}>
                <Countdown />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <ProgressRank />
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <Metrics />
              </Col>
              <Col md={8}>
                <Sides />
              </Col>
            </Row>

            <Row>
              <Col md={3}>
                <Directs />
              </Col>
              <Col md={9}>
                <Locations />
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  );
};

export default withNamespaces()(Dashboard);
