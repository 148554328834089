import React, {useState, useEffect} from "react";
import {Input, Button, Label, Form, FormGroup} from "reactstrap";

import {getAllCountries, getCountryStates, getStateCities} from '../../../../utils/CountryStateCityApi';


import '../Styles/Register.css'
import ISelect from "../../../../components/Select";
import toastr from "toastr";

const AddressInfo = (props) => {

    const [address, setAddress] = useState("");
    const [addressOpt, setAddressOpt] = useState("");
    const [postal, setPostal] = useState("");

    useEffect(() => {
        document.body.classList.add("auth-body-bg");
    }, []);

    const handleChange = (e, setState) => {
        setState(e.target.value);
        props.handleChange(e.target.id, e.target.value);
    }

    const validate = () => {
        return address && postal;
    }

    const next = () => {
        if (validate()) {
            props.setStep(props.step + 1)
        } else {
            toastr.error('There are no fields to be entered', 'Error');
        }
    }

    return (
        <div>
            <div className="izeven-text font-size-18 mb-4">
                <i onClick={() => props.setStep(props.step - 1)}
                   className="fas fa-arrow-left  text-center izeven-text"/> Back
            </div>

            <h4 className="text-center izeven-text">ADDRESS INFO</h4>
            <p className="text-center text-secondary">Let us get to know you...</p>

            <div>
                <Form className="form-horizontal mb-2">
                    <FormGroup>
                        <Label>Address</Label>
                        <Input
                            value={address} onChange={(e) => handleChange(e, setAddress)}
                            id="address"
                            name="address"
                            type="text"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label>Address 2 (optional)</Label>
                        <Input
                            value={addressOpt} onChange={(e) => handleChange(e, setAddressOpt)}
                            id="addressOpt"
                            name="addressOpt"
                            type="text"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label>Postal code</Label>
                        <Input
                            value={postal} onChange={(e) => handleChange(e, setPostal)}
                            id="postal_code"
                            name="postal_code"
                            type="text"
                        />
                    </FormGroup>

                    <div className="text-center">
                        <Button onClick={next} color="primary" className="w-md waves-effect waves-light">Next</Button>
                    </div>
                </Form>
            </div>
        </div>
    );
}

export default AddressInfo;
