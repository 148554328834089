import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { get } from "../../../helpers/Api";
import authHeader from "../../../helpers/Api/auth-header";
import { Card, CardBody, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { withNamespaces } from "react-i18next";
const SideLink = (props) => {

    const { user } = useSelector((state) => state.user);
    const [linkSide, setLinkSide] = useState('random');

    const updateSide = () => {
        get('api/settings/side/' + linkSide, { headers: authHeader() })
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        if (user.settings) {
            setLinkSide(user.settings.link_side);
        }
    }, [user]);

    useEffect(() => {
        updateSide();
    }, [linkSide]);

    return (
        <Card>
            <CardBody>
                <Row className="content-center align-items-center">
                    <Col md="4">
                        <Row className="font-size-18 mt-2  ">
                            <Col className="mb-3 izeven-text" md={12}>
                                <i className="fas fa-link" style={{ marginRight: '10px' }} />
                                <span>Invite Link Settings</span>
                            </Col>
                        </Row>
                        <p style={{ textAlign: 'justify' }}>
                            {props.t('Select side')}
                        </p>
                    </Col>
                    <Col />
                    <Col md="7" className="text-center">
                        <Form style={{ fontSize: '20px' }} onChange={(e) => setLinkSide(e.target.value)}>
                            <FormGroup
                                style={{ marginRight: '30px' }}
                                check
                                inline
                            >
                                <Input checked={linkSide === 'left'} value="left" name="link-side" type="radio" />
                                <Label radioGroup className="mb-0">
                                    {props.t('Left')}
                                </Label>
                            </FormGroup>
                            <FormGroup
                                style={{ marginRight: '30px' }}
                                check
                                inline
                            >
                                <Input checked={linkSide === 'right'} value="right" name="link-side" type="radio" />
                                <Label radioGroup className="mb-0">
                                    {props.t('Right')}
                                </Label>
                            </FormGroup>
                            <FormGroup
                                style={{ marginRight: '30px' }}
                                check
                                inline
                            >
                                <Input checked={linkSide === 'random'} value="random" name="link-side" type="radio" />
                                <Label radioGroup className="mb-0">
                                    {props.t('Random')}
                                </Label>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )

}

export default withNamespaces()(SideLink);
