import OrgChart from "@balkangraph/orgchart.js";

const initOrgChat = () => {
  console.log(OrgChart.isTrial());
  console.log(OrgChart.VERSION);
  const arrow =
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="270px" y="17px"' +
    'width="15px" height="15px" fill="#373A3F" viewBox="0 0 199.404 199.404" style="enable-background:new 0 0 199.404 199.404;"' +
    'xml:space="preserve">' +
    "<g>" +
    '<rect x="0" y="0" height="200" width="200" fill="#fff"></rect>' +
    // eslint-disable-next-line no-tabs
    '<polygon points="199.404,63.993 171.12,35.709 99.702,107.127 28.284,35.709 0,63.993 99.702,163.695 	"/>' +
    "</g>" +
    "</svg>";
  OrgChart.templates.cool = Object.assign({}, OrgChart.templates.ana);
  OrgChart.templates.cool.defs =
    '<filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="cool-shadow"><feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1" /><feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1" /><feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" in="shadowBlurOuter1" type="matrix" result="shadowMatrixOuter1" /><feMerge><feMergeNode in="shadowMatrixOuter1" /><feMergeNode in="SourceGraphic" /></feMerge></filter>';
  OrgChart.templates.cool.size = [310, 190];
  OrgChart.templates.cool.profile_img =
    '<clipPath id="{randId}"><rect fill="#ffffff" stroke="#039BE5" stroke-width="5" x="10" y="10" rx="10" ry="10" width="80" height="100"></rect></clipPath><image preserveAspectRatio="xMidYMid slice" clip-path="url(#{randId})" xlink:href="{val}" x="10" y="10"  width="80" height="100"></image><rect fill="none" stroke="#373A3F" stroke-width="2" x="10" y="10" rx="10" ry="10" width="80" height="100"></rect>';
  OrgChart.templates.cool.name =
    '<text data-width="130px" data-text-overflow="ellipsis" style="font-size: 18px;" fill="#afafaf" x="110" y="30">{val}</text>';
  OrgChart.templates.cool.plan =
    '<text  style="font-size: 14px;" fill="#373A3F" x="20" y="145">{val}</text>';
  OrgChart.templates.cool.email =
    '<text style="font-size: 14px;" fill="#afafaf" x="20" y="165">{val}</text>';
  OrgChart.templates.cool.rank_img =
    '<image  xlink:href="{val}" x="243" y="123" width="55" height="55"></image>';
  OrgChart.templates.cool.percent =
    '<text style="font-size: 24px;" fill="#373A3F" x="150" y="90" >{val}%</text>';
  OrgChart.templates.cool.svg =
    '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="background-color:#f1f5f7;display:block;" width="{w}" height="{h}" viewBox="{viewBox}">{content}</svg>';
  OrgChart.templates.cool.node =
    '<rect filter="url(#cool-shadow)" x="0" y="0" height="190" width="310" fill="#ffffff" stroke-width="1" stroke="#eeeeee" rx="10" ry="10"></rect><rect fill="#ffffff" x="100" y="10" width="200" height="100" rx="10" ry="10" filter="url(#cool-shadow)"></rect><rect stroke="#eeeeee" stroke-width="1" x="10" y="120" width="220" fill="#ffffff" rx="10" ry="10" height="60"></rect><rect stroke="#eeeeee" stroke-width="1" x="240" y="120" width="60" fill="#ffffff" rx="10" ry="10" height="60"></rect><text style="font-size: 11px;" fill="#afafaf" x="110" y="60">Siguiente</text>' +
    '<image xlink:href="https://izeven-bucket.nyc3.cdn.digitaloceanspaces.com/images/velocimeter.png" x="110" y="65" width="42" height="42"></image>' +
    arrow +
    '<rect id="this_tree" class="arrow" stroke="#eeeeee" stroke-width="1" x="250" y="0" width="60" fill="red" rx="10" ry="10" height="60" opacity="0"></rect>';

  OrgChart.templates.cool.editFormHeaderColor = "#373A3F";
  OrgChart.templates.cool.backgroundColor = "white";

  OrgChart.templates.empty = Object.assign({}, OrgChart.templates.cool);
  OrgChart.templates.empty.node =
    '<rect filter="url(#cool-shadow)"  x="0" y="0" height="190" width="310" fill="#ffffff" stroke-width="1" stroke="#eeeeee" rx="10" ry="10"></rect>' +
    '<circle cx="155" cy="95" fill="#ffffff" r="30" stroke="#afafaf" stroke-width="2"></circle>' +
    '<circle cx="155" cy="95" fill="#afafaf" r="23" stroke="#afafaf" stroke-width="2"></circle>' +
    '<line x1="140" y1="95" x2="170" y2="95" stroke-width="4" stroke="#ffffff" />' +
    '<line x1="155" y1="80" x2="155" y2="110" stroke-width="4" stroke="#ffffff" />';
};

const config = (nodes) => {
  return {
    enableSearch: false,
    showYScroll: OrgChart.scroll.visible,
    mouseScrool: OrgChart.action.yScroll,
    template: "cool",
    nodes: nodes,
    align: OrgChart.ORIENTATION,
    toolbar: {
      zoom: true,
      fit: true,
    },
    editForm: {
      cancelBtn: "Close",
      saveAndCloseBtn: "Save",
      photoBinding: "profile_img",
      generateElementsFromFields: false,
      elements: [
        { type: "textbox", label: "Name", binding: "name" },
        { type: "textbox", label: "Email", binding: "email" },
        { type: "textbox", label: "Phone", binding: "phone" },
      ],
      buttons: null,
      addMore: null,
      addMoreBtn: null,
    },
    nodeBinding: {
      name: "name",
      email: "email",
      phone: "phone",
      plan: "plan",
      rank_img: "rank_img",
      percent: "percent",
      profile_img: "profile_img",
    },
    tags: {
      empty: {
        template: "empty",
      },
    },
  };
};

const configDirect = (nodes) => {
  return {
    enableSearch: false,
    showYScroll: OrgChart.scroll.visible,
    mouseScrool: OrgChart.action.yScroll,
    template: "cool",
    nodes: nodes,
    user: [],
    searchFields: ["id"],
    orientation: OrgChart.orientation.left,
    toolbar: {
      zoom: true,
      fit: true,
    },
    editForm: {
      cancelBtn: "Close",
      saveAndCloseBtn: "Save",
      photoBinding: "profile_img",
      generateElementsFromFields: false,
      elements: [
        { type: "textbox", label: "Name", binding: "name" },
        { type: "textbox", label: "Email", binding: "email" },
        { type: "textbox", label: "Phone", binding: "phone" },
      ],
      buttons: null,
      addMore: null,
      addMoreBtn: null,
    },
    nodeBinding: {
      name: "name",
      email: "email",
      phone: "phone",
      plan: "plan",
      rank_img: "rank_img",
      percent: "percent",
      profile_img: "profile_img",
    },
    tags: {
      empty: {
        template: "empty",
      },
    },
  };
};

export { OrgChart, initOrgChat, config, configDirect };
