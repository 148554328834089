import React, {useEffect, useRef} from "react";
import {OrgChart, initOrgChat, config, configDirect} from "../../../helpers/OrgChart";

import '../../../assets/css/network.css';
import {useSelector} from "react-redux";

const Network = ({nodes}) => {

    let chart;
    const divRef = useRef();

    const {user} = useSelector((state) => state.user);

    useEffect(() => {
        initOrgChat()
    }, []);

    useEffect(() => {

        // Initialization of chart
        chart = new OrgChart(divRef.current, configDirect(nodes));

        // Click function in all cases.
        chart.on('click', (sender, args) => {
            const target = args.event.target
            if (target && target.classList.contains('arrow')) {
            } else if (args.node.templateName === 'empty') {
                sender.editUI.show(args.node.id, false)
            } else {
                sender.editUI.show(args.node.id, true)
            }

            return false
        })

        // Fields to show.
        chart.editUI.on('field', function (sender, args) {
            if (args.name === 'plan') {
                return false
            }
            if (args.name === 'rank_img') {
                return false
            }
            if (args.name === 'percent') {
                return false
            }
            if (args.name === 'next') {
                return false
            }
            if (args.name === 'code_id') {
                return false
            }
            if (args.name === 'binary_side') {
                return false
            }
            if (args.name === 'link') {
                return false
            }
            if (args.name === 'Add new field') {
                return false
            }
        });

    }, [nodes]);

    return (
        <div id="tree" ref={divRef}/>
    )
}

export default React.memo(Network);
