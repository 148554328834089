import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import moment from "moment";
import "./css/index.css";
import { withNamespaces } from "react-i18next";

const Countdown = (props) => {
  const targetTime = moment().endOf("month");

  const [currentTime, setCurrentTime] = useState(moment());
  const timeBetween = moment.duration(targetTime.diff(currentTime));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Card>
      <CardBody>
        <div className="mb-4">
          <h4 className="card-title">
            La <strong className="text-primary">Carrera de Liderazgo</strong>{" "}
            Termina En:
          </h4>
        </div>

        <Row className="text-center" style={{ margin: "0px" }}>
          <Col md={12}>
            <Row className="countdown">
              <Col className="py-1 mb-2">
                <h1>
                  {timeBetween.days().toString().length >= 2
                    ? timeBetween.days()
                    : "0" + timeBetween.days()}
                </h1>
                <p>{props.t("DAYS")}</p>
              </Col>
              <Col className="py-1 mb-2">
                <h1>
                  {timeBetween.hours().toString().length >= 2
                    ? timeBetween.hours()
                    : "0" + timeBetween.hours()}
                </h1>
                <p>{props.t("HOURS")}</p>
              </Col>
              <Col className="py-1 mr-2 mb-2">
                <h1>
                  {timeBetween.minutes().toString().length >= 2
                    ? timeBetween.minutes()
                    : "0" + timeBetween.minutes()}
                </h1>
                <p>MIN</p>
              </Col>
              <Col className="py-1 mb-2">
                <h1>
                  {timeBetween.seconds().toString().length >= 2
                    ? timeBetween.seconds()
                    : "0" + timeBetween.seconds()}
                </h1>
                <p>{props.t("SEC")}</p>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="mt-4">
          <small>
            La carrera de liderazgo es clave para determinar tu rango dentro de
            la compañía. Tu progreso en la carrera se mide por una serie de
            hitos que debes alcanzar para ascender a un nuevo nivel de
            liderazgo.
          </small>
        </div>
      </CardBody>
    </Card>
  );
};

export default withNamespaces()(Countdown);
