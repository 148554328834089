import React, { useState, useEffect } from "react";
import { Input, Button, Label, Form, Alert, FormGroup } from "reactstrap";
import toastr from "toastr";
import { countriesPhoneCodes } from "../../../../utils/CountryStateCityApi";
import ISelect from "../../../../components/Select";

const LoginInfo = (props) => {
  const [codes, setCodes] = useState([]);

  const [email, setEmail] = useState("");
  const [phoneCode, setPhoneCode] = useState(null);
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    document.body.classList.add("auth-body-bg");
    countriesPhoneCodes().then((response) => {
      setCodes(response);
    });
  }, []);

  const handleChange = (e, setState, id = null) => {
    setState(e.target ? e.target.value : e.value);
    props.handleChange(
      id ? id : e.target.id,
      e.target ? e.target.value : e.value
    );
  };

  const validate = () => {
    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        toastr.error("The passwords doesnt match.", "Error");
      }
    }

    return (
      email &&
      phone &&
      password &&
      confirmPassword &&
      password === confirmPassword
    );
  };

  const next = () => {
    if (validate()) {
      props.setStep(props.step + 1);
    } else {
      toastr.error("There are no fields to be entered", "Error");
    }
  };

  return (
    <div>
      <div className="izeven-text font-size-18 mb-4">
        <i
          onClick={() => props.setStep(props.step - 1)}
          className="fas fa-arrow-left  text-center izeven-text"
        />{" "}
        Back
      </div>

      <h4 className="mt-4 text-center izeven-text">LOGIN INFO</h4>

      <p className="text-center text-secondary">Let us get to know you...</p>

      <div className=" mt-4">
        <Form className="form-horizontal mb-4">
          <FormGroup>
            <Label>Email</Label>
            <Input
              className="mb-2"
              type="text"
              value={email}
              onChange={(e) => handleChange(e, setEmail)}
              name="email"
              id="email"
            />
          </FormGroup>

          <FormGroup>
            <Label>Phone Code</Label>
            <Input
              className="mb-2"
              type="text"
              value={phoneCode}
              onChange={(e) => handleChange(e, setPhoneCode)}
              name="phone_code"
              id="phone_code"
            />
            {/*<ISelect value={phoneCode} onChange={(e) => handleChange(e, setPhoneCode, 'phone_code')} options={codes} placeholder="Select your phone code." />*/}
          </FormGroup>

          <FormGroup>
            <Label>Phone</Label>
            <Input
              className="mb-2"
              type="number"
              value={phone}
              onChange={(e) => handleChange(e, setPhone)}
              name="phone"
              id="phone"
            />
          </FormGroup>

          <FormGroup>
            <Label>Password</Label>
            <Input
              className="mb-2"
              type="password"
              value={password}
              onChange={(e) => handleChange(e, setPassword)}
              name="password"
              id="password"
            />
          </FormGroup>

          <FormGroup>
            <Label>Confirm Password</Label>
            <Input
              className="mb-2"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              name="confirm_password"
              id="confirm_password"
            />
          </FormGroup>

          <div className="text-center">
            <Button
              onClick={next}
              color="primary"
              className="w-md waves-effect waves-light"
            >
              Next
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default LoginInfo;
