import React from "react";
import Cards from "react-credit-cards";

import './Styles/index.css';

const CreditCard = (props) => {
    return (
        <Cards {...props} />
    )
}

export default CreditCard;
