import React from 'react';

const AppButton =(props)=> {

        return (
            <>
                <a className="dropdown-icon-item" href={props.link} target="_blank">
                    <img src={props.image}  />
                    <span className={props.textStyle}>{props.title}</span>
                </a>
            </>
        );
}

export default AppButton;
