import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import moment from "moment";
import "./css/index.css";
import { withNamespaces } from "react-i18next";

const CicleCountdown = (props) => {
  const today = moment();
  const dayOfMonth = today.date();
  const lastDayOfMonth = today.endOf("month").date();

  const targetTime =
    dayOfMonth <= 15 ? today.date(15) : today.date(lastDayOfMonth);

  const [currentTime, setCurrentTime] = useState(moment());
  const timeBetween = moment.duration(targetTime.diff(currentTime));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Card>
      <CardBody>
        <div className="mb-4">
          <h3 className="card-title">
            El <strong className="text-primary">Ciclo</strong> Terminara En:
          </h3>
        </div>

        <Row className="text-center" style={{ margin: "0px" }}>
          <Col md={12}>
            <Row className="countdown">
              <Col className="py-1 mb-2">
                <h1>
                  {timeBetween.days().toString().length >= 2
                    ? timeBetween.days()
                    : "0" + timeBetween.days()}
                </h1>
                <p>{props.t("DAYS")}</p>
              </Col>
              <Col className="py-1 mb-2">
                <h1>
                  {timeBetween.hours().toString().length >= 2
                    ? timeBetween.hours()
                    : "0" + timeBetween.hours()}
                </h1>
                <p>{props.t("HOURS")}</p>
              </Col>
              <Col className="py-1 mr-2 mb-2">
                <h1>
                  {timeBetween.minutes().toString().length >= 2
                    ? timeBetween.minutes()
                    : "0" + timeBetween.minutes()}
                </h1>
                <p>MIN</p>
              </Col>
              <Col className="py-1 mb-2">
                <h1>
                  {timeBetween.seconds().toString().length >= 2
                    ? timeBetween.seconds()
                    : "0" + timeBetween.seconds()}
                </h1>
                <p>{props.t("SEC")}</p>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="mt-4">
          <small>
            ¡No olvides! A partir de ahora, los ciclos durarán 15 días, y
            tendrás la oportunidad de recibir un bono en función de la cantidad
            de puntos que acumules durante este período. Asegúrate de aprovechar
            al máximo cada día para alcanzar tus objetivos. ¡El bono está a tu
            alcance!
          </small>
        </div>
      </CardBody>
    </Card>
  );
};

export default withNamespaces()(CicleCountdown);
