import { Col, FormGroup, Input, Label, Modal, Row } from "reactstrap";

const Cripto = ({ toggle, isOpen }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="modal-dialog-centered modal-lg"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Pago con Cripto <strong className="text-primary">USDT</strong>
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggle}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Row className="justify-content-center">
          <h5 className="text-primary">¿Cómo pagar?</h5>
          <p>
            Para realizar un pago con la criptomoneda <strong>USDT</strong>,
            elige la membresía que deseas y realiza el pago a la siguiente
            dirección de billetera digital (wallet). Recuerda que si deseas
            incluir el valor del IPA, deberás sumar $35 USD al valor de la
            membresía.
          </p>
          <hr />
          <img
            style={{ width: "50%" }}
            src="https://izeven-bucket.nyc3.cdn.digitaloceanspaces.com/Izeven/QR%20Wallet%20Izeven%20USDT_.png"
          />
          <hr />
          <Col md={12} style={{ textAlign: "center" }}>
            <div style={{ width: "100%" }}>
              <FormGroup>
                <Label className="text-primary">Wallet ID:</Label>
                <Input
                  style={{ width: "70%", margin: "0px auto" }}
                  readOnly
                  value={"TTcaxiJvdNB38xtoeURrLxE9EQb23tK5Ug"}
                />
              </FormGroup>
            </div>
          </Col>
          <hr />
          <Col>
            <h5 className="text-primary">IMPORTANTE: </h5>
            <p>
              Una vez que realices la transacción, envíanos tu comprobante de
              transacción Screemshop + ID de transacción + Correo electrónico
              con el cual estás registrado a nuestro soporte{" "}
              <a href="https://wa.link/pjgqkj" target="_blank">
                +573208789399
              </a>
              , en un periodo máximo de 1 hora estará tu membresía activa.
            </p>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default Cripto;
