import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import authHeader from "../../helpers/Api/auth-header";
import {Container} from "reactstrap";
import {get} from "../../helpers/Api";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Tree from "./Components/Tree";
import {withNamespaces} from "react-i18next";

const Generation = (props) => {

    const {user} = useSelector((state) => state.user);

    const [nodes, setNodes] = useState([]);

    let breadcrumbItems = [
        {title: "iZeven", link: "/"},
        {title: props.t('Generation Tree'), link: "#"},
    ];

    const getNetwork = async () => {
        const url = `${process.env.REACT_APP_NETWORK_SERVICE_URL}api/network/direct/${user.id}`;

        await get(url, {headers: authHeader()})
            .then(response => {
                setNodes([
                    ...response.master_user,
                    ...response.network_1,
                    ...response.network_2,
                    ...response.network_3
                ]);
            })
    }

    useEffect(() => {
        getNetwork();
    }, [])

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={props.t('Generation Tree')} breadcrumbItems={breadcrumbItems}/>

                    <Tree
                        nodes={nodes}
                        getNetwork={getNetwork}
                    />
                </Container>
            </div>
        </>
    )
}

export default withNamespaces() (Generation);
