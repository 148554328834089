import React, {useRef} from "react";
import {Card, Row, Col, Label, Input, Button, Alert, Progress, Form, FormGroup} from "reactstrap";

import identificationImage from '../../../../assets/images/kyc/identification.png';
import addressImage from '../../../../assets/images/kyc/address.png';
import passportImage from '../../../../assets/images/kyc/passport.png';
import {withNamespaces} from "react-i18next";
import convertToBase64 from "../../../../helpers/Convert/base64";
import {axiosApi} from "../../../../helpers/Api";
import authHeader from "../../../../helpers/Api/auth-header";
import toastr from "toastr";
import {useDispatch, useSelector} from "react-redux";
import {checkAuth} from "../../../../features/userSlice";

const KYCVerification = (props) => {

    const {user} = useSelector((state) => state.user);

    const dispatch = useDispatch()

    const identificationRef = useRef();
    const addressRef = useRef();
    const passportRef = useRef();

    const handleChange = (e, document) => {
        const file = e.target.files[0];
        convertToBase64(file)
            .then(response => {
                update(response, document).then(() => dispatch(checkAuth()));
            })
    }

    const update = async (file, document) => {
        await axiosApi.post('api/profile/kyc/' + document, {document: file}, {headers: authHeader()})
            .then(response => {
                toastr.success('Document uploaded to review.', 'Success');
            })
            .catch(error => {
                console.log(error);
            })
    }

    const kycStatus = () => {
        if (user.kyc) {
            if (user.kyc.status) {
                return '(Approved)';
            }
            else if (!user.kyc.status && user.kyc.message) {
                return '(Rejected)';
            }
            else if (!user.kyc.status) {
                if (!user.kyc.identification_card || !user.kyc.validation_address) {
                    return '';
                }

                return '(In Revision)';
            }
        } else {
            return '';
        }
    }

    return (
        <>
            <Row className="mt-4 py-3 content-center" style={{marginRight: '5px', marginLeft: '20px'}}>
                <Col md="3" className="mb-4">
                    <Row className="font-size-18 mt-2  ">
                        <Col className="mb-3 izeven-text" md={12}>
                            <i className="fas fa-fingerprint" style={{marginRight: '10px'}}/>
                            <span>{props.t('KYC Verification')} { kycStatus() }</span>
                        </Col>
                    </Row>
                    <p style={{textAlign: 'justify'}}>
                        {props.t('Following docs')}
                    </p>
                    {
                        user.kyc && user.kyc.message ?
                            <p style={{ textAlign: 'justify' }} className="text-danger">Declined by:  { user.kyc.message }</p> : null
                    }
                </Col>
                <Col/>
                <Col md="8">
                    <Row>
                        <Col md={4}>
                            <Card className="align-items-center">
                                <img src={identificationImage} width="30%"/>
                                <h5 className="font-size-13 mt-3">{props.t('Identification Card')} {user.kyc && user.kyc.identification_card ?
                                    <a href={user.kyc.identification_card} target="_blank"><i className="fas fa-eye"/></a> : null}
                                </h5>
                                <h6 className="font-size-12 text-primary">{ user.kyc && user.kyc.identification_card ? 'Uploaded' : 'Not Uploaded' }</h6>
                                <a onClick={() => identificationRef.current.click()} className="mb-4 mt-4">
                                    <i className="fas fa-upload"/>
                                    {
                                        user.kyc && user.kyc.identification_card ? 'Click to Change' : 'Click to Upload'
                                    }
                                </a>
                            </Card>
                            <input type="file" ref={identificationRef}
                                   onChange={(e) => handleChange(e, 'identification')} hidden/>
                        </Col>
                        <Col md={4}>
                            <Card className="align-items-center">
                                <img src={addressImage} width="30%"/>
                                <h5 className="font-size-13 mt-3">{props.t('Address Document')} {user.kyc && user.kyc.validation_address ?
                                    <a
                                        href={user.kyc.validation_address} target="_blank"><i className="fas fa-eye"/></a> : null} </h5>
                                <h6 className="font-size-12 text-primary">{ user.kyc && user.kyc.validation_address ? 'Uploaded' : 'Not Uploaded' }</h6>
                                <a onClick={() => addressRef.current.click()} className="mb-4 mt-4">
                                    <i className="fas fa-upload"/>
                                    {
                                        user.kyc && user.kyc.validation_address ? 'Click to Change' : 'Click to Upload'
                                    }
                                </a>
                            </Card>
                            <input type="file" ref={addressRef} onChange={(e) => handleChange(e, 'address')} hidden/>
                        </Col>
                        <Col md={4}>
                            <Card className="align-items-center">
                                <img src={passportImage} width="30%"/>
                                <h5 className="font-size-13 mt-3">{props.t('Passport')} {user.kyc && user.kyc.passport ?
                                    <a
                                        href={user.kyc.passport} target="_blank"><i className="fas fa-eye"/></a> : null} </h5>
                                <h6 className="font-size-12 text-primary">{ user.kyc && user.kyc.passport ? 'Uploaded' : 'Not Uploaded' }</h6>
                                <a onClick={() => passportRef.current.click()} className="mb-4 mt-4">
                                    <i className="fas fa-upload"/>
                                    {
                                        user.kyc && user.kyc.passport ? 'Click to Change' : 'Click to Upload'
                                    }
                                </a>
                            </Card>
                            <input type="file" ref={passportRef} onChange={(e) => handleChange(e, 'passport')} hidden/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default withNamespaces()(KYCVerification);
