import React, {useEffect, useState} from "react";
import {
    Button,
    Card, CardBody, CardTitle,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import Swal from "sweetalert2";
import ipaContract from "../../../utils/ipaContract";
import {axiosApi} from "../../../helpers/Api";
import authHeader from "../../../helpers/Api/auth-header";
import {withNamespaces} from "react-i18next";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

const Withdrawal = ({wallet, toggle, isOpen, getWallet, t}) => {

    const {user} = useSelector((state) => state.user);

    const [amount, setAmount] = useState(null);
    const [method, setMethod] = useState(null)

    const [amountAvailable, setAmounAvailable] = useState(0);

    const canWithdraw = () => {
        if (amount > 0 && amount <= wallet.amount_available && method != null) {
            if (method === 'BANK') {
                return !(kycApproved() && bankCertificate());
            } else if (method === 'TETHER') {
                return !(kycApproved() && user.tether_wallet);
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    const kycApproved = () => {
        return user.kyc && user.kyc.status;
    }

    const bankCertificate = () => {
        return user.bank_info && user.bank_info.bank_certificate;
    }

    const makeWithdrawal = async () => {

        await axiosApi.post('api/wallet/withdrawal', {amount: amount, method: method}, {headers: authHeader()})
            .then(response => {
                getWallet();
                toggle();
                Swal.fire({
                    title: 'Success',
                    icon: 'success',
                    text: 'Your withdrawal request was sent successfully, our team will review the request and make the transfer.',
                    confirmButtonColor: "#11EDFC",
                    confirmButtonText: 'Confirm'
                })
            })
            .catch(error => {
                console.log(error);
            })
    }

    const requestWithdrawal = () => {
        Swal.fire({
            text: `Are you sure to make a withdrawal of $${amount} USD to your ${method === 'BANK' ? 'bank account' : 'tether wallet'}?`,
            confirmButtonColor: "#11EDFC",
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            showCancelButton: true
        })
            .then(result => {
                if (result.isConfirmed) {
                    makeWithdrawal();
                }
            })
    }

    useEffect(() => {
        setAmounAvailable((wallet.amount_available - amount));
    }, [amount])

    useEffect(() => {
        setAmounAvailable(wallet.amount_available)
    }, [wallet])

    return (
        <Modal centered toggle={toggle} isOpen={isOpen}>
            <ModalHeader charCode="Y" toggle={toggle}/>
            <ModalBody>
                <h3 className="mb-3 text-izeven">{t('Withdraw your earnings')}</h3>
                <Form>
                    <FormGroup floating className="mb-1">
                        <Input
                            type="text"
                            name="number"
                            id="number"
                            placeholder={t('How much')}
                            className="form-control form-control-lg"
                            onChange={(e) => setAmount(e.target.value)}
                            value={amount}
                        />
                    </FormGroup>
                    <p>{t('You can withdraw up to')} $ {wallet.amount_available} {t('from your Available')}</p>
                </Form>
                <hr/>
                <p>{t('Receive money in')}</p>
                <Form onChange={(e) => setMethod(e.target.value)}>
                    <FormGroup
                        check
                        inline
                    >
                        <Input value="BANK" name="link-side" type="radio"/>
                        <Label radioGroup className="mb-0">
                            {t('Bank Account')}
                        </Label>
                    </FormGroup>
                    <FormGroup
                        check
                        inline
                    >
                        <Input value="TETHER" name="link-side" type="radio"/>
                        <Label radioGroup className="mb-0">
                            {t('Tether Wallet')}
                        </Label>
                    </FormGroup>
                </Form>
                <hr/>
                <p>{t('Your wallet would be with')}:</p>
                <h2 className="text-izeven">$ {amountAvailable} <small>USD</small></h2>
                {
                    !kycApproved() || (method === 'BANK' && !bankCertificate()) || (method === 'TETHER' && !user.tether_wallet) || (amount > wallet.amount_available) ?
                        <>
                            <hr/>
                            <Card color="danger">
                                <CardBody className="text-white">
                                    <CardTitle className="fw-bold"><i className="mdi mdi-block-helper me-3"/> You can’t
                                        make withdrawals </CardTitle>
                                    <ul>
                                        {
                                            kycApproved() ? null :
                                                <li>You have not uploaded your KYC documents, are not yet approved, or
                                                    have been rejected.</li>
                                        }
                                        {
                                            method === 'BANK' && !bankCertificate() ?
                                                <li>You have not uploaded your bank certificate.</li> : null
                                        }
                                        {
                                            method === 'TETHER' && !user.tether_wallet ?
                                                <li>You have not registered your Tether wallet</li> : null
                                        }
                                        {
                                            amount > wallet.amount_available ?
                                                <li>You have not enough USD available in your wallet</li> : null
                                        }
                                    </ul>
                                    {
                                        amount > wallet.amount_available ? null : <>
                                            <hr/>
                                            <p>You can upload this info in your <Link className="text-white"
                                                                                      to='/profile'>Profile.</Link></p>
                                        </>
                                    }
                                </CardBody>
                            </Card>
                        </> : null
                }
            </ModalBody>
            <ModalFooter>
                <Button disabled={canWithdraw()} color="primary" onClick={requestWithdrawal}>
                    {t('Make Withdrawal')}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default withNamespaces()(Withdrawal);
