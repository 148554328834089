import React, {useEffect, useRef, useState} from "react";
import {Card, Row, Col, Label, Input, Button, FormGroup, Form} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {withNamespaces} from "react-i18next";
import convertToBase64 from "../../../../helpers/Convert/base64";
import './styles/index.css';
import {axiosApi} from "../../../../helpers/Api";
import authHeader from "../../../../helpers/Api/auth-header";
import toastr from "toastr";
import {checkAuth} from "../../../../features/userSlice";

const BankForm = (props) => {

    const {user} = useSelector((state) => state.user);
    const dispatch = useDispatch()

    const certRef = useRef();

    const [bankName, setBankName] = useState("");
    const [bankCountry, setBankCountry] = useState("");
    const [bankState, setBankState] = useState("");
    const [bankCity, setBankCity] = useState("");
    const [swiftCode, setSwiftCode] = useState("");
    const [accountType, setAccountType] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [tax, setTax] = useState("");
    const [certificate, setCertificate] = useState("");

    const handleChange = (e) => {
        const file = e.target.files[0]
        convertToBase64(file)
            .then(response => {
                setCertificate(response);
            })
    }

    const update = () => {

        const data = {
            bank_name: bankName,
            bank_country: bankCountry,
            bank_state: bankState,
            bank_city: bankCity,
            swift_code: swiftCode,
            account_type: accountType,
            account_number: accountNumber,
            tax_id: tax,
            bank_certificate: certificate
        }

        axiosApi.put('api/profile/bank_info/update',data, {headers: authHeader()})
            .then(response => {
                setCertificate('');
                toastr.success('Your bank info was updated.', 'Success');
                dispatch(checkAuth());
            })
            .catch(error => {
                console.log(error);
            })

    }

    useEffect(() => {
        if (user.bank_info) {
            setBankName(user.bank_info.bank_name)
            setBankCountry(user.bank_info.bank_country)
            setBankState(user.bank_info.bank_state)
            setBankCity(user.bank_info.bank_city)
            setSwiftCode(user.bank_info.swift_code)
            setAccountType(user.bank_info.account_type)
            setAccountNumber(user.bank_info.account_number)
            setTax(user.bank_info.tax_id)
        }
    }, [user])

    return (
        <>
            <Row className="mt-4 py-3 content-center mx-4 ">
                <Col md="4" className="mb-4">
                    <Row className="font-size-18 mt-2  ">
                        <Col className="mb-3 izeven-text" md={12}>
                            <i className="fas fa-piggy-bank" style={{marginRight: '10px'}}/>
                            <span>{props.t('Bank Account Info')}</span>
                        </Col>
                    </Row>
                    <p style={{textAlign: 'justify'}}>
                        {props.t('Bank associate')}
                    </p>
                </Col>
                <Col/>
                <Col md="7">
                    <Form>
                        <FormGroup>
                            <Label>{props.t('Bank Name')}</Label>
                            <Input id="bankName" value={bankName} onChange={(e) => setBankName(e.target.value)}
                                   type="text"/>
                        </FormGroup>
                        <FormGroup>
                            <Label>{props.t('Bank Country')}</Label>
                            <Input id="bankCountry" value={bankCountry} onChange={(e) => setBankCountry(e.target.value)}
                                   type="text"/>
                        </FormGroup>
                        <FormGroup>
                            <Label>{props.t('Bank State')}</Label>
                            <Input id="bankState" value={bankState} onChange={(e) => setBankState(e.target.value)}
                                   type="text"/>
                        </FormGroup>
                        <FormGroup>
                            <Label>{props.t('Bank City')}</Label>
                            <Input id="bankCity" value={bankCity} onChange={(e) => setBankCity(e.target.value)}
                                   type="text"/>
                        </FormGroup>
                        <FormGroup>
                            <Label>{props.t('Swift Code')}</Label>
                            <Input id="swiftCode" value={swiftCode} onChange={(e) => setSwiftCode(e.target.value)}
                                   type="text"/>
                        </FormGroup>
                        <FormGroup>
                            <Label>{props.t('Account Type')}</Label>
                            <Input id="accountType" value={accountType} onChange={(e) => setAccountType(e.target.value)}
                                   type="text"/>
                        </FormGroup>
                        <FormGroup>
                            <Label>{props.t('Bank Account Number')}</Label>
                            <Input id="bankNumber" value={accountNumber}
                                   onChange={(e) => setAccountNumber(e.target.value)} type="text"/>
                        </FormGroup>
                        <FormGroup>
                            <Label>{props.t('Tax Id')}</Label>
                            <Input id="tax" value={tax} onChange={(e) => setTax(e.target.value)} type="text"/>
                        </FormGroup>

                        <FormGroup>
                            <Label>{props.t('Bank Certificate')}</Label>
                            <div>
                                {
                                    user.bank_info && user.bank_info.bank_certificate && !certificate ?
                                        <a href={user.bank_info.bank_certificate} target="_blank" style={{ width: '100%' }} className="btn btn-block btn-primary mb-2"><i className="fas fa-eye"/> See Current Certificate</a> : null
                                }
                                <a onClick={() => certRef.current.click()} className="btn btn-block btn-izeven-outline">
                                    <i className={ 'fas ' + (!certificate ? 'fa-upload' : 'fa-check') }/>
                                    {
                                        !certificate ? (user.bank_info && user.bank_info.bank_certificate ? ' Click to Change Certificate' : '  Click to Upload') : '  File Charged'
                                    }
                                </a>
                            </div>
                            <input onChange={handleChange} ref={certRef} type="file" hidden/>
                        </FormGroup>

                        <Button onClick={update} color="primary"
                                className="w-md waves-effect waves-light"
                        >{props.t('Update')}</Button>
                    </Form>
                </Col>

            </Row>

        </>
    );
}

export default withNamespaces()(BankForm);
