import React, {useState} from "react";
import {Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {withNamespaces} from "react-i18next";
import {axiosApi} from "../../../../helpers/Api";
import authHeader from "../../../../helpers/Api/auth-header";
import toastr from "toastr";

const PasswordChange = (props) => {

    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const update = () => {

        if ((password && currentPassword && confirmPassword) && password === confirmPassword)
        {
            const data = {
                current_password: currentPassword,
                new_password: password
            }

            axiosApi.post('api/settings/change-password', data, {headers: authHeader()})
                .then(response => {
                    toastr.success(response.data.message, 'Success');
                })
                .catch(error => {
                    if (error.response) {
                        toastr.error(error.response.data.message, 'Error');
                    } else {
                        toastr.error('Something was wrong, try again later.', 'Server Error');
                    }
                });
        } else if (!(password && currentPassword && confirmPassword)) {
            toastr.error('All fields are required', 'Error');
        } else {
            toastr.error('Passwords doesnt match.', 'Error');
        }
    }

    return (
        <Card>
            <CardBody>
                <Row className="content-centerpassword">
                    <Col md="4">
                        <Row className="font-size-18 mt-2  ">
                            <Col className="mb-3 izeven-text" md={12}>
                                <i className="fas fa-lock-open" style={{marginRight: '10px'}}/>
                                <span>{props.t('Change your password')}</span>
                            </Col>
                        </Row>
                        <p style={{textAlign: 'justify'}}>
                            {props.t('Password Change')}
                        </p>
                    </Col>
                    <Col md="8">
                        <Form>
                            <FormGroup>
                                <Label>{props.t('Current Password')}</Label>
                                <Input id="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} type="password"/>
                            </FormGroup>
                            <FormGroup>
                                <Label>{props.t('Password')}</Label>
                                <Input id="password" value={password} onChange={(e) => setPassword(e.target.value)} type="password"/>
                            </FormGroup>
                            <FormGroup>
                                <Label>{props.t('Confirm Password')}</Label>
                                <Input id="confirm_password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} type="password"/>
                            </FormGroup>
                            <Button onClick={update} color="primary"
                                    className="w-md waves-effect waves-light"
                            >{props.t('Update')}</Button>
                        </Form>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default withNamespaces()(PasswordChange);
