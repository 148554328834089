import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { withNamespaces } from "react-i18next";
import convertToBase64 from "../../helpers/Convert/base64";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { axiosApi } from "../../helpers/Api";
import authHeader from "../../helpers/Api/auth-header";
import toastr from "toastr";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const IUPPage = ({ t }) => {
  const TERMS =
    "https://izeven.sfo3.cdn.digitaloceanspaces.com/IUP/2022/Te%CC%81rminos%20y%20Condiciones%20I%20UP%203.0%20-%20COPYT.pdf";
  const CONTRACT_PRO_TRADING_FX =
    "https://izeven.sfo3.cdn.digitaloceanspaces.com/IUP%2F2022%2Fcf-autogestion_M.E.pdf";
  const CONTRACT_COPYTRADING =
    "https://izeven.sfo3.cdn.digitaloceanspaces.com/IUP/IUP_CONTRATO%20IUP.pdf";

  const [data, setData] = useState({});

  const history = useHistory();
  const { user } = useSelector((state) => state.user);

  let breadcrumbItems = [
    { title: "iZeven", link: "/" },
    { title: t("I-UP"), link: "#" },
  ];

  const handleChange = (e) => {
    const tmp = { ...data };

    if (e.target.files) {
      const file = e.target.files[0];
      convertToBase64(file).then((response) => {
        tmp[e.target.name] = response;
        setData(tmp);
      });
    } else {
      tmp[e.target.name] = e.target.value;
    }

    setData(tmp);
  };

  const submit = async () => {
    await axiosApi
      .post("api/iup", { ...data, user_id: user.id }, { headers: authHeader() })
      .then((response) => {
        toastr.success(
          "Registro exitoso, pronto recibiras mas información.",
          "Success"
        );
      })
      .catch((error) => {
        toastr.error(
          "Algo salio mal, Puede ser que los documentos excedan el peso permitido por favor intente comprimirlos a menos de 5mb. O contacte con soporte."
        );
        console.log(error);
      });
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title={t("I-UP")} breadcrumbItems={breadcrumbItems} />
        <Row>
          <Col md={12}>
            <Card>
              <CardHeader charCode="Y" />
              <CardBody>
                <h3 className="mb-3 text-izeven">{t("Registrate")}</h3>
                <Form>
                  <FormGroup className="mb-4">
                    <Label className="mb-0">Balance y/o Deposito</Label>
                    <Input
                      type="number"
                      name="balance"
                      id="balance"
                      className="form-control form-control-lg"
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <h5 className="mb-3 text-izeven">
                    {t("Terminos y Condiciones")}{" "}
                    <a target="_blank" href={TERMS} className="btn btn-primary">
                      <i className="fa fa-download" /> Descargar
                    </a>{" "}
                  </h5>
                  <p>
                    Para comenzar a disfrutar de este servicio primero debes
                    leer y firmar los siguientes{" "}
                    <a target="_blank" href={TERMS}>
                      Terminos & Condiciones
                    </a>{" "}
                    y adjuntarlo acontinuación.
                  </p>
                  <FormGroup className="mb-4">
                    <Label className="mb-0">
                      Terminos y Condiciones firmados.
                    </Label>
                    <Input
                      type="file"
                      name="signed_terms"
                      id="signed_terms"
                      className="form-control form-control-lg"
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <hr />
                  <h5 className="mb-3 text-izeven">
                    {t("Contrato COPYTRADING")}{" "}
                    <a
                      target="_blank"
                      href={CONTRACT_COPYTRADING}
                      className="btn btn-primary"
                    >
                      <i className="fa fa-download" /> Descargar
                    </a>{" "}
                  </h5>
                  <p>
                    Por ultimo lea y firme el siguiente{" "}
                    <a target="_blank" href={CONTRACT_COPYTRADING}>
                      contrato para trabajar con COPYTRADING
                    </a>{" "}
                    y adjuntarlo acontinuación.
                  </p>
                  <FormGroup className="mb-4">
                    <Label className="mb-0">Contrato firmado.</Label>
                    <Input
                      type="file"
                      name="signed_contract_copytrading"
                      id="signed_contract_copytrading"
                      className="form-control form-control-lg"
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter style={{ textAlign: "right" }}>
                <Button onClick={submit} color="primary">
                  {t("Registrarme")}
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withNamespaces()(IUPPage);
